.report {
  display: flex;
  flex-direction: column;
  padding: 100px;
  justify-content: space-evenly;
  overflow: scroll;
  height: max-content;
}
/* .line-graph {
  height: 100px;
} */

.App .main-content .report-tabs .ant-tabs-ink-bar {
  display: none !important;
}

.App .main-content .report-tabs .ant-tabs-nav-scroll {
  background: #eaf0fa;
  box-shadow: inset 0px 6px 9px -11px black, 0px 0px 0px 0px, 0px 0px 0px 0px,
    0px 0px 0px 0px;
}

.main-content .report-tabs .ant-tabs-nav .ant-tabs-tab {
  /* color: #1a3062; */
  font-weight: 400;
  color: #777777;
}
.main-content .report-tabs .ant-tabs-nav .ant-tabs-tab-active {
  color: #252525 !important;
  font-weight: 500 !important;
  background-color: #f7f9fb !important;
  box-shadow: inset 0px 6px 9px -11px black, 0px 0px 0px 0px, 0px 0px 0px 0px,
    0px 0px 0px 0px;
}

.App .main-content .report-tabs .ant-tabs-nav {
  width: 100%;
  display: contents;
}
.App .main-content .report-tabs .ant-tabs-nav-container {
  line-height: 0.5;
}

.main-content .report-tabs .ant-tabs-nav .ant-tabs-tab {
  padding: 19px 19px;
}

.tab-title-container {
  display: flex;
  flex-direction: row;
}
.tab-title-image {
  width: 25px;
  height: 25px;
  margin-top: -11px;
  margin: -6px 5px -7px 0;
}

.main-content
  .report-tabs
  .ant-tabs-nav
  .ant-tabs-tab-active
  .tab-title-image
  path {
  fill: #4672ba;
}

.tab-title-image path {
  fill: #8aabe0;
}

.ant-input:focus {
  box-shadow: none !important;
}
