.main-content .ant-tabs-ink-bar {
  background-color: #f4c15c !important;
  height: 3px !important;
}

.main-content .ant-tabs-nav .ant-tabs-tab-active {
  color: #1a3062 !important;
  font-weight: 700 !important;
}

.main-content .ant-tabs-nav .ant-tabs-tab {
  font-weight: 600;
  color: #a3aab6;
}

.App .main-content .ant-tabs-nav-scroll {
  display: flex;
  justify-content: space-around;
  background: white;
}

.ant-drawer-close {
  width: 20px;
  height: 20px;
  margin: -5px 224px 0px 0px;
}

.main-content-blur {
  filter: blur(3px);
}

.logo-top {
  pointer-events: none;
}

.App .main-content .ant-tabs-nav {
  width: 100%;
}

.App .main-content .ant-tabs-nav .ant-tabs-nav:first-child,
.App .main-content .ant-tabs-nav .ant-tabs-nav-animated:first-child {
  display: flex;
  justify-content: space-around;
  width: 45%;
}

.logout-container {
  display: flex;
}

.main-content .logout-container .ant-btn:active {
  border: 0px solid;
  box-shadow: none;
}

/* .main-content .ant-btn:not([disabled]):hover {
  border: 0px solid;
} */

.main-content .logout-container .ant-btn {
  margin: 13px;
  background-color: rgb(255, 255, 255);
  border: 0px solid;
  box-shadow: none;
}

.main-content .logout-button {
  position: absolute;
  margin-top: -32px;
  margin-left: -42px;
  background: #040c30;
  color: white;
}

.main-content .logout-container .ant-btn:hover {
  color: #010d42;
}

.main-content .ant-tabs-bar {
  background-color: white;
}

.App .main-content .ant-tabs-tab {
  width: inherit;
}

.main-content .ant-tabs-nav .ant-tabs-tab {

  font-weight: 600;
  color: #a3aab6;
}

.main-content .ant-tabs-nav .ant-tabs-tab:hover {
  font-weight: 600;
  color: #a3aab6;
}

.main-content .ant-tabs-nav .ant-tabs-tab {
  text-align: center;
}

.main-content .ant-tabs-nav .ant-tabs-tab {
  padding: 19px 16px;
  margin: 0px 0px 0px 0px !important;
}

.main-content .ant-tabs-bar {
  margin: 0 0 0px 0;
}

.toolbar-extra {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 15px 0 0;
}

.toolbar-extra .burger-menu {
  margin: 5px 0;
  cursor: pointer;
}

/* Drawer */

.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper,
.ant-drawer-right .ant-drawer-content-wrapper {
  margin: 1% !important;
  height: 96.5%;
  overflow: hidden;
  border-radius: 10px;
}

.toolbar-extra .ant-drawer-body {
  padding: 0px !important;
}

.ant-drawer-wrapper-body {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.differentation-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: fit-content;
  padding: 0px;
  margin: 0px -7px 0px 0px;
  text-align: center;
  z-index: 1000;
}

.differentation-button-container .ant-btn>.anticon {
  line-height: 1;
  margin-top: 5px;
  margin-right: 5px;
  margin-left: -4px;
}

.differentation-button-container .ant-btn {
  background-color: darkorange !important;
}

.differentation-button-container .ant-btn-round.ant-btn-lg {
  height: 30px;
  font-size: 15px;
  font-weight: 500;
  padding-top: 5px;
}

.differentation-button-container .ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff !important;
  background-color: darkorange !important;
  border-color: darkorange !important;
  cursor: default;
}