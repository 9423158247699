.quality-check-modal-card-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid #a3aab6; */
  /* border-radius: 5px; */
  /* box-shadow: 1px 2px 5px 0px; */
  padding: 5px 5px;
  margin: 10px 0 10px 0;
  width: 100%;
}
.quality-check-modal-card-container .quality-check-modal-card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-left: 1px solid #a3aab6;
  border-top: 1px solid #a3aab6;
  border-bottom: 1px solid #a3aab6;
  /* border-right: 0px; */
  border-radius: 5px 0 0 5px;
  box-shadow: -5px -1px 5px 0px;
  padding: 5px 5px;
  margin: 10px 0 10px 0;
  width: 40%;
  height: 100px;
}

.quality-check-modal-card-container .title {
  width: 10%;
  border: 1px solid #808080ab;
  border-radius: 5px;
  padding: 19px 20px 16px 20px;
  box-shadow: 1px 2px 5px 0px;
  background: #154479;
  color: white;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  width: 20%;
}

.quality-check-modal-card-container .description {
  width: 40%;
  border: 1px solid #808080ab;
  border-radius: 5px;
  padding: 18px 8px;
  margin: 0 10px 0 10px;
  box-shadow: 1px 2px 5px 0px;
  width: 70%;
}

.quality-check-modal-card-container .score {
  width: 10%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.quality-check-modal-card-container .score .round {
  display: flex;
  justify-content: center;
  width: 50px;
  height: 50px;
  border: 1px solid #a5a5a5;
  border-radius: 25px;
  align-self: center;
  /* background-color: #73de73; */
  /* margin: 0 0 10px 0; */
}
.quality-check-modal-card-container .score .round .value {
  font-size: 18px;
  font-weight: 700;
  margin: 10px 0 0 0;
}

.quality-check-modal-card-container .qc-image {
  width: 40%;
}

.quality-check-modal-card-container .graph-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  height: 300px;
  border: 1px solid #a3aab6;
  border-radius: 5px;
  box-shadow: 1px 2px 5px 0px;
}
