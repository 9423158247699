.weekly-volume-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #f7f9fb;
}


.weekly-volume-container .top-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  width: 90%;
}

.weekly-volume-container .middle-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.weekly-volume-container .top-container .filters {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 75%;
  margin: 8px 0px 0 300px;
}

.weekly-volume-container .top-container .filters .temporal-select {
  margin: 9px 10px;
  padding: 5px 10px;
}

.weekly-volume-container .top-container .total-target-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 25%;
  margin: 15px 0;
  padding: 8px 6px;
  border: 1px solid #8080808f;
  border-radius: 5px;
}

.weekly-volume-container .top-container .total-target-container .title {
  border: 1px solid #80808061;
  border-radius: 5px;
  padding: 6px 10px;
  height: 40px;
  width: 155px;
  font-size: 14px;
  font-weight: 700;
  margin: 0 5px;
  background-color: white;
}

.weekly-volume-container .top-container .total-target-container .info {
  white-space: nowrap;
  border: 1px solid #80808061;
  border-radius: 5px;
  padding: 6px 5px;
  height: 40px;
  font-size: 14px;
  font-weight: 700;
  background-color: white;
}

/* projected price display for weeks 1,2 and 3 */

.weekly-volume-container .middle-container .projection-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  width: 68%;
  border: 1px solid #808080ab;
  border-radius: 5px;
  padding: 16px 20px;
  box-shadow: 1px 2px 5px 0px;
  margin: 20px 0 0 0;
}

.weekly-volume-container .middle-container .projection-container .row-titles,
.weekly-volume-container .bottom-container .override-details .row-titles {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border: 1px solid #a3aab6;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #4672ba;
  padding: 22px 5px 0px 5px;
  text-align: right;
  white-space: nowrap;
}

.weekly-volume-container .middle-container .qc-details {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border: 1px solid #a3aab6;
  border-radius: 5px;
  margin: 20px 0 0 2%;
  padding: 5px 15px;
  background: white;
  box-shadow: 1px 2px 5px 0px;
  width: 20%;
}

.weekly-volume-container .middle-container .qc-details .qc-title {
  font-size: 13px;
  font-weight: 700;
  color: #4672ba;
  text-align: center;
  margin: 0 0 8px 0;
}

.weekly-volume-container .middle-container .qc-details .round {
  display: flex;
  justify-content: center;
  width: 50px;
  height: 50px;
  border: 1px solid #a5a5a5;
  border-radius: 25px;
  align-self: center;
  margin: 0 0 10px 0;
}

.weekly-volume-container .middle-container .qc-details .round .score {
  font-size: 18px;
  font-weight: 700;
  margin: 10px 0 0 0;
}

.weekly-volume-container .middle-container .qc-details .no-score {
  font-size: 13px;
  font-weight: 500;
  color: black;
  text-align: center;
  margin: 0 0 8px 0;
}

.weekly-volume-container .middle-container .qc-details .ant-btn {
  background-color: #4672ba;
  color: white;
}

.weekly-volume-container .graph-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.weekly-volume-container .graph-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 28px 10px;
  border: 1px solid #808080ab;
  border-radius: 5px;
  box-shadow: 1px 2px 5px 0px;
  background: white;
}

.weekly-volume-container .graph-info .graph-title {
  font-size: 15px;
  font-weight: 700;
  color: #4672ba;
  margin: 18px 0 0 0;
}

.weekly-volume-container .graph-info .graph {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 250px;
  align-items: center;
  margin: 30px 0 0 0;
}

.weekly-volume-container .bottom-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  width: 90%;
}

.weekly-volume-container .bottom-container .override-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  border: 1px solid #808080ab;
  border-radius: 5px;
  padding: 16px 20px 0 16px;
  box-shadow: 1px 2px 5px 0px;
  margin: 5px 0 40px 0;
}

.weekly-volume-container .bottom-container .override-details {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  padding: 16px 20px;
  margin: 7px 0 4px 0;
  background: white;
}

.weekly-volume-container .bottom-container .override-details-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;

  border-radius: 5px;
  box-shadow: 1px 2px 5px 0px;
  padding: 16px 20px;
  margin: 7px 0 15px 0;
  background: white;
}

.weekly-volume-container .bottom-container .override-details-data .error-log-container {
  display: flex;
  flex-direction: row;
  justify-content: center;

  padding: 0px 20px;
  background: white;
}

.weekly-volume-container .bottom-container .override-details-data .error-log-container .error-log-title {
  border: 1px solid #808080ab;
  border-radius: 5px;
  padding: 6px 11px 9px 12px;
  box-shadow: 1px 2px 5px 0px;
  background: #154479;
  color: white;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  margin: -2px 5px 0 0;
  width: 15%;
}

.weekly-volume-container .bottom-container .override-details-data .error-log-container .error-log-entry {
  width: 85%;
}

.weekly-volume-container .bottom-container .button-container {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
}

.weekly-volume-container .bottom-container .button-container-override {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  width: 100%;
  justify-content: space-between;
}

.weekly-volume-container .bottom-container .override-button {
  align-self: flex-end;
  border: 1px solid #dc3b3b;
  border-radius: 5px;
  color: white;
  background: #dc3b3b;
  width: 135px;
  padding: 5px;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
}

.weekly-volume-container .bottom-container .finalise-button {
  border: 1px solid #808080ab;
  border-radius: 5px;
  padding: 6px 11px 9px 12px;
  box-shadow: 1px 2px 5px 0px;
  background: #154479;
  color: white;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  margin: -2px 5px 0 0;
  cursor: pointer;
}

.weekly-volume-container .bottom-container .submit-button {
  align-self: flex-end;
  border: 1px solid #73de73;
  border-radius: 5px;
  color: white;
  background: #85da85;
  width: 135px;
  padding: 5px;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
}

.weekly-volume-container .bottom-container .cancel-button {
  align-self: flex-end;
  border: 1px solid #dc3b3b;
  border-radius: 5px;
  color: white;
  background: #dc3b3b;
  width: 135px;
  padding: 5px;
  margin: 0 5px 0 0;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
}

.weekly-volume-container .projection-period {
  width: 150px;
  font-size: 14px;
  color: #4672ba;
  font-weight: 700;
  text-align: left;
  align-self: center;
  flex-direction: column;
}

.weekly-volume-container .empty-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;

  border: 1px solid #808080ab;
  border-radius: 5px;
  box-shadow: 1px 2px 5px 0px;
  background: white;
  width: 90%;
  height: 173px;
  margin: 0 0 35px 0;
}

.weekly-volume-container .empty-box span {
  color: red;
}

.weekly-modal .ant-modal-header {
  text-align: center;
}

.weekly-volume-container .disabled-button {
  background-color: #a4a5a7 !important;
  color: white !important;
  pointer-events: none;
}