.price-trend-chart {
  padding: 16px 16px;
  margin: 6px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 99%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 2px 2px 12px -7px black;
}

.price-trend-container {
  display: flex;
  flex-direction: column;

  height: 100%;
  overflow: scroll;
}

.price-trend-chart .legend {
  display: flex;
  padding: 0px 5px 0 28px;
  margin: 0px 72px 3px 5px;
  flex-direction: row;
  justify-content: space-between;
}

.legend-details {
  display: flex;
  margin: 5px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-end;
}

.price-trend-chart .legend .box {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-left: 5px;
}

.price-trend-chart .legend .box-label {
  margin-top: -3px;
  margin-left: 5px;
  font-size: 11px;
}

.charts {
  display: flex;
  flex-direction: row;
  height: 260px;
  overflow: scroll;
  padding-top: 5px;

  margin-bottom: -152px;
}

.all-branches {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.doughnut {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  width: 100%;
  height: 300px;
}

.chart-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

/* for filters */

.user-adoption-container .summary .ant-select-selection {
  color: #313131 !important;
}

.user-adoption-container .ant-calendar-range-picker-input {
  width: 44%;
  color: #313131;
  font-weight: 500;
}

/* .price-trend-container .summary .ant-select-selection {
  background-color: white;
  color: #727272;
  padding-left: 8px;
  font-size: 14px;
  width: 150px;
  border: 0px solid #d9d9d9;
} */
.filter-container {
  margin-bottom: 25px;
  background: transparent;
}

.price-trend-container .summary .summary-filters {
  justify-content: space-around;
}

.price-trend-container .summary .ant-select-selection:hover,
.price-trend-container .summary .ant-select-selection:focus {
  border: none !important;
}

.price-trend-container .ant-calendar-picker-input {
  outline: none;
  border: none;
  margin-left: -6px;
}

.ant-select-arrow .ant-select-arrow-icon svg {
  color: #acacac;
}

.filter-select {
  border-radius: 8px;
  padding: 5px 2px 0px 2px;
  background-color: white;
  border: 1px solid rgb(221, 221, 221);
  box-shadow: 2px 2px 6px -6px black;
}

.filter-select-title {
  color: rgb(172, 172, 172);
  padding-left: 7%;
  margin-bottom: -5px;
  font-size: 11px;
}

.user-adoption-container .filter-select .ant-select-show-arrow .ant-select-selection--multiple .ant-select-selection__rendered {
  margin-left: 0px;
  margin-top: -3px;
  overflow: hidden !important;
  height: 35px;
}

/* Bottom legends */

.legend-line-bar-main-container {
  margin-top: 5px;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.legend-line-bar-container {
  width: 230px;
  display: flex;
  justify-content: space-between;
}

.bar-legend {
  width: 35px;
  height: 15px;
  box-shadow: 2px 2px 2px -1px;
  border: 1px solid white;
  background: linear-gradient(135deg,
      rgba(2, 81, 250, 1) 0%,
      rgba(94, 186, 244, 1) 25%,
      rgba(168, 99, 250, 1) 50%,
      rgba(247, 76, 122, 1) 75%);
}

.line-legend {
  margin-top: 8px;
  width: 45px;
  height: 2px;
  box-shadow: 1px 1px 2px 0px;
  background: linear-gradient(90deg,
      rgba(2, 81, 250, 1) 0%,
      rgba(94, 186, 244, 1) 25%,
      rgba(168, 99, 250, 1) 50%,
      rgba(247, 76, 122, 1) 75%);
  display: flex;
  justify-content: space-around;
}

.circle-in-line {
  margin-top: -4px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  border: 1px solid white;
  box-shadow: 1px 1px 2px 0px;
  background: linear-gradient(135deg,
      rgba(2, 81, 250, 1) 0%,
      rgba(94, 186, 244, 1) 25%,
      rgba(168, 99, 250, 1) 50%,
      rgba(247, 76, 122, 1) 75%);
}

.leg-name {
  margin-top: -2px;
  font-weight: 600;
  color: #2e2e2e;
}