.priorityYes {
    color: rgb(116, 177, 46);
}

.priorityNo {
    color: rgb(255, 3, 26);
}

.date-picker .ant-calendar-picker-input.ant-input {
    width: 50% !important;
    padding-left: 10px !important;
}

.mandi-master-container .ant-calendar-picker-clear,
.ant-calendar-picker-icon {
    display: inline-block !important;
    font-size: 15px;
    top: 53% !important;
    /* right: 57% !important; */
}
.table-container1 .ant-calendar-picker-icon {
    display: inline-block !important;
    font-size: 15px;
    top: 53% !important;
    right: 57% !important;
}



.ant-input-disabled {
    color: black !important;
}

.master-form-container .checkbox-group1 .ant-input {
    color: black !important;
    margin-left: 0px !important;
}

.master-form-container .checkbox-group1 .ant-calendar-picker-icon {
    display: none !important;
}

.master-form-container .ant-select-selection {
    box-sizing: border-box;
    display: flex;
    background-color: transparent;
    border: 0px solid #d9d9d9;
    color: black;
    font-size: 13px;
    font-weight: 500;
}

.master-form-container .ant-select-selection--single {
    box-sizing: border-box;
    background-color: white;
    border: 1px solid #d9d9d9;
    height: 35px;
    color: black !important;
}

.master-form-container .ant-calendar-picker {
    margin-left: -5px;
    width: 105px !important;
}

.mandi-master-container .add-master-modal .ant-modal-body {
    overflow: auto;
    height: 415 !important;
}

.mandi-master-container .checkbox-group1 .anticon.anticon-close-circle.ant-calendar-picker-clear {
    display: inline-block !important;
}

.mandi-master-container .checkbox-group1 .ant-calendar-picker-icon {
    display: none !important;
}

.mandi-master-container .anticon.anticon-close-circle.ant-calendar-picker-clear {
    display: inline-block !important;
}

.disabledBtnClass {
    background-color: grey !important;
    color: black !important;
    opacity: 0.5;
}

.btnClass {
    background-color: "#20346a",
}

.mandi-master-container .top-container .hub-region-filter {
    display: flex;
    margin-left: 25%;
    display: flex;
}

.select-master-region {
    width: 170px !important;
    height: 32px !important;
}