.user-form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.user-form-container .form-top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.user-form-container .form-bottom-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 24px 0 0 0;
}
.form-top-right .ant-form-item .ant-form-item-control{
  line-height: 0px !important;
}
.form-top-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
}
.form-top-right {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  width: 50%;
}

.form-radio-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* border: 1px solid gray; */
  width: 90%;
  border-radius: 5px;
  height: max-content;
  margin: 7px 0px 0 21px;
  box-shadow: -1px -1px 4px 0px;
}

.user-form-container .ant-select,
.ant-cascader-picker {
  width: 470px !important;
  border-radius: 5px;
  /* height: 33px; */
  border: 1px solid #80808047;
}

.user-form-container .ant-switch {
  height: 32px;
  width: 100px;
}

.user-form-container .ant-switch::after {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
}

.user-form-container .ant-switch-checked::after {
  left: 100%;
  margin-left: 0px;
}

.user-form-container .ant-form-item-label {
  line-height: 24px;
}

.user-form-container .ant-select-selection {
  font-size: 14px;
  font-weight: 400;
}

.user-form-container .ant-input-number {
  width: 100% !important;
}

.user-form-container .ant-radio-wrapper {
  margin: 10px 10px 10px 10px;
  padding: 0 10px;
  width: 100%;
}

.user-form-container .ant-radio {
  margin: 0 0 0px 170px;
}

.user-form-container span.ant-radio + * {
  padding-right: 8px;
  padding-left: 8px;
  margin-left: -192px;
}

.user-form-container .ant-btn {
  background-color: #154479;
}
.user-form-container .ant-btn:hover {
  background-color: #154479;
}
