.weekly-quality-check-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.weekly-quality-check-container .total-info-container {
  display: flex;
  flex-direction: row;
  border: 1px solid #a3aab6;
  border-radius: 5px;
  box-shadow: 1px 2px 5px 0px;
  width: 40%;
  /* padding: 5px 5px; */
  /* margin: 10px 0 10px 0; */
}
.weekly-quality-check-container .total-info-container .title {
  width: 85%;
  border: 1px solid #808080ab;
  border-radius: 5px;
  padding: 19px 20px 16px 20px;
  box-shadow: 1px 2px 5px 0px;
  background: #154479;
  color: white;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}

.weekly-quality-check-container .score {
  width: 15%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.weekly-quality-check-container .score .round {
  display: flex;
  justify-content: center;
  width: 50px;
  height: 50px;
  border: 1px solid #a5a5a5;
  border-radius: 25px;
  align-self: center;
  /* background-color: #73de73; */
  /* margin: 0 0 10px 0; */
}
.weekly-quality-check-container .score .round .value {
  font-size: 18px;
  font-weight: 700;
  margin: 10px 0 0 0;
}
