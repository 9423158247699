.trails-table-component {
  color: rgb(82, 82, 255);
  text-decoration: underline;
  cursor: pointer;
}

.history-report-container {
  display: flex;
  flex-direction: column;
  background-color: #f7f9fb;
  overflow: scroll;
  height: max-content;
}

.history-table-container {
  padding: 0px 10px;
  margin-top: -12px;
}

.history-table-container .ant-table-bordered .ant-table-thead>tr:not(:last-child)>th {
  background: #030a30;
  border-bottom: none;
}

.history-table-container .ant-table-bordered .ant-table-thead>tr:not(:last-child)>th:not(:last-child) {
  border-right: 1px solid #ffffff0f;
}

.history-table-container .ant-table-bordered .ant-table-thead>tr>th {
  border-right: 1px solid #ffffff0f;
  background: #0a123c;
}

.history-table-container .ant-table-thead>tr>th,
.history-table-container .ant-table-tbody>tr>td {
  padding: 8px 8px;
  overflow-wrap: break-word;
}

.history-table-container .ant-table-thead>tr>th .ant-table-header-column {
  font-size: 12px;
  font-weight: normal;
}

.history-download-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: fit-content;
  padding: 3px 0;
  text-align: center;
  position: fixed;
  z-index: 1000;
}

.history-download {
  display: flex;
  position: fixed;
  padding: 8px 11px;
  width: 45px;
  height: 45px;
  border-radius: 26px;
  bottom: 50px;
  right: 50px;
  background-color: #030a30;
  color: #eff3f6;
  overflow: hidden;
  transition: all 0.5s;
}

.history-download:hover {
  display: flex;
  position: fixed;
  padding: 8px 11px;
  border-radius: 26px;
  width: 119px;
  height: 95px;
}

/* .progress-download-container {
  margin: 50px 13px 0px -45px;
  color: #eff3f6;
} */

.progress-download-container {
  display: flex;
  position: fixed;
  padding: 8px 20px;
  width: 45px;
  height: 45px;
  border-radius: 26px;
  bottom: 55px;
  right: 20px;
  color: #eff3f6;
 
}


.d-icon {
  margin: 6px 17px 0px 5px;
  transition: all 0.5s;
  height: 16px;
}

.dwnld {
  margin-top: 2px;
  height: 33px;
  text-align: start;
  opacity: 0;
  transition: all 0.5s;
  cursor: default;
}

.history-download:hover .dwnld {
  opacity: 1;
  margin-left: -10px;
}

.history-download .ant-btn {
  padding: 0px 8px;
  margin: 5px 10px 0px 0px;
  border-radius: 22px;
  height: 20px;
  color: #030a30;
  background-color: #fff;
  width: 64px;
  text-align: start;
}

.history-download .ant-btn:hover {
  color: #6666ff;
}

.history .ant-modal-content {
  border-radius: 20px;
  overflow: hidden;
}

.history .ant-modal-header {
  background: #eff3f6;
  padding: 12px 24px;
}

.history .ant-modal-content {
  background-color: #fff;
}