.add-variety-container {
  margin: 35px 25px 0 25px;
  display: flex;
  flex-direction: column;
  min-width: 265px;
  width: 265px;
  justify-content: center;
  background-color: #cfd7e5;
  border-radius: 5px;
  border: 2px dashed #294371;
}

.add-variety-container-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.add-variety-container .add-text {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: #294371;
  margin: 5px 0 0px 0;
  cursor: default;
}

.add-variety-container .add-image {
  width: 100%;
  height: 35px;
  margin: 0px 0 5px 0;
  cursor: pointer;
}