.user-group-form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.user-group-form-container .form-top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.user-group-form-container .form-bottom-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin: 24px 0 0 0;
}

.form-top-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.user-group-form-container .ant-select,
.ant-cascader-picker {
  width: 100%;
  border-radius: 5px;
  /* height: 33px; */
  border: 1px solid #80808047;
}

.user-group-form-container .ant-form-item-label {
  line-height: 24px;
}

.user-group-form-container .ant-select-selection {
  font-size: 14px;
  font-weight: 400;
}

.user-group-form-container .ant-input-number {
  width: 100% !important;
}

.user-group-form-container .ant-btn {
  background-color: #154479;
}
.user-group-form-container .ant-btn:hover {
  background-color: #154479;
}

.select-user-group-container {
  display: flex;
  flex-direction: column;
  width: 182px;
}
.select-user-group-container .select-info {
  font-size: 11px;
  display: flex;
  flex-direction: row;
  margin: 10px 0 0 0;
  /* justify-content: space-between; */
}
.select-user-group-container .select-info .user-info-role {
  margin: 0px 20px 5px 0px;
  width: 70%;

  /* white-space: nowrap; */
}
.select-user-group-container .select-info .user-info-branch {
  margin: 0px 20px 5px 0px;
  width: 30%;

  /* white-space: nowrap; */
}

.user-group-select
  .ant-select-dropdown.ant-select-dropdown--multiple
  .ant-select-dropdown-menu-item
  .ant-select-selected-icon {
  top: 20% !important;
}
