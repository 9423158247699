.visualization-graph-container {
}

.visualization-graph-container .graphs-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 1300px;
  margin: 10px 0 0 -49px;
}
