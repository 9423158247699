.ant-modal-content {
  max-height: 500px;
}

.varaital-submit-btn {
  text-align: right;
  margin-right: 20px;
  height: 50px;
}

.disable-edit-btn {
  background-color: grey !important;
  color: black !important;
  opacity: 0.5;
}

.edit-btn {
  background-color: "#20346a";
}

.deficitRow {
  color: rgb(255, 3, 26);
}

.surplusRow {
  color: rgb(116, 177, 46);
}

.varietal {
  .ant-modal-body {
    height: 80%;
    padding-top: 5px;
  }
}

.row {
  .ant-input {
    margin-top: 2px;
    height: 10%;
    margin-left: 10px;
  }
}

.unit {
  width: 150px;
}

.varietal-audit-table {
  max-height: 400px !important;
  margin-bottom: 20px !important;
  background-color: #f7f9fb;
}

.btn-container-freeze {
  display: flex;
  flex-direction: column;
  place-items: flex-end;
}
