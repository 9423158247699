.visualization-graph-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-items: center;
  background: white;
  margin: 10px 0 36px -119px;
  border-radius: 10px;
  width: -moz-fit-content;
  width: fit-content;
}
.visualization-graph-container .bar-graph {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-items: center;
}
.visualization-graph-container .bar-graph .bar-graph-title {
  font-size: 15px;
  font-weight: 700;
  color: #4672ba;
  margin: 18px 0 0 0;
  text-align: center;
}

.visualization-graph-container .legend-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.visualization-graph-container .legend-container .legend-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px;
}

.visualization-graph-container .legend-container .legend-box .legend-fill {
  width: 30px;
  height: 15px;
}
.visualization-graph-container .legend-container .legend-box .legend-title {
  font-weight: 600;
  font-weight: 600;
  margin: -4px 0px 0 7px;
}
