.home-image-container {
  border-radius: 30px;
  box-shadow: 0px 5px 16px -7px;
  border: solid #f4c15c;
  border-width: 4px;
  margin-bottom: 75px;
  width: max-content;
  overflow: hidden;
}

.home-heading {
  margin-top: 15px;
  font-size: 30px;
  color: #1a3062;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif,
    BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", cursive;
  font-weight: 500;
}

.dashboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.dashboard .branch-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  width: 200px;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 1;
  position: absolute;
  margin: 30px 30px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 5px 5px 14px -5px;
}

.branch-info .branches {
  color: #0c1059;
  font-size: 14px;
  margin: 10px 5px;
  min-height: 25px;
}

.branch-info .branches .branch-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.branch-info .branches .branch-item .round {
  /* border: 1px solid black; */
  height: 10px;
  width: 10px;
  border-radius: 5px;
  margin: 6px 0 0 0px;
}

.branch-info .branches .branch-item .left {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: flex-start;
}

.branch-info .branches .branch-item .name {
  padding: 0 5px;
}

.branch-info .branches .branch-item .value {
  padding: 0 5px;
  width: 25%;
}

.branch-info .switch-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #f4f2f3;
  border-radius: 0 0 7px 7px;
  height: 40px;
  color: #0c1059;
  font-size: 14px;
}

.branch-info .switch-container .switch {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.branch-info .switch-container .switch .tag {
  margin: 0 5px;
  font-weight: 600;
}

.dashboard .dashboard-map-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.dashboard .dashboard-map-container .leaflet-container {
  height: 90vh;
  width: 100%;
  z-index: 0;
}

.dashboard
  .dashboard-map-container
  .leaflet-container
  .leaflet-control-container {
  display: none;
}

.switch-container.home .ant-switch {
  background-color: #1a3062;
}
.switch-container.home .ant-switch::after {
  background-color: white;
}
