.fixed-projections-line-graph-container {
  background: rgb(247 249 251);
}

.temporal-select.center {
  height: 70px;
  width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px;
}