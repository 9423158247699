.modal-input {
    margin-top: 1em;

    .ant-form-item {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      margin-bottom: 10px;
    }
  
    .ant-btn {
      margin-top: 15px;
      background-color: #f1bf1e;
      color: #030b30;
      font-weight: 600;
      border: none;
      width: 100%;
      height: 41px;
      border-radius: 6px;
    }
  
    .ant-btn:focus {
      outline: none;
    }
  
    .ant-btn:hover {
      color: white;
      background-color: #f1bf1e;
    }
  }
  