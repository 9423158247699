.summary .ant-table table {

  vertical-align: middle;
}

.ant-table-thead>tr>th .ant-table-header-column {
  font-size: 11px;
}

.summary .ant-table-tbody>tr>td {
  color: #262626;
  padding: 3px 16px;
  font-weight: 600;
}

.ant-spin {
  color: #004eff;
}

.ant-spin-dot-item {
  background-color: #004eff;
}

.back-button-container {
  top: 45px;
  left: -37px;
  cursor: pointer;
  position: absolute;
  color: #27406b;
  font-weight: 600;
  width: 125px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 5px;
  font-weight: 500;
  margin-bottom: -25px;
}

.summary .ant-table-thead>tr>th {
  padding: 10px 16px;
  text-align: left;
  font-weight: normal;
}

.summary .ant-table-thead>tr>th .ant-table-header-column {
  font-size: 12px;
}

.summary .ant-table-thead>tr>th:nth-child(10),
.summary .ant-table-thead>tr>th:nth-child(11) {
  vertical-align: middle;
  padding: 10px 5px 10px 16px;
  text-align: center;
}

.summary .ant-table-thead>tr:nth-child(2)>th {
  padding: 5px 5px 5px 8px;
}

.audit-table .ant-table-thead>tr>th {
  vertical-align: top;
  padding: 7px 16px;
  font-weight: normal;
  text-align: start;
}

.audit-table .ant-table-thead>tr>th .ant-table-header-column {
  font-size: 12px;
}

.audit-table .ant-table-thead>tr:first-child>th:nth-child(4),
.audit-table .ant-table-thead>tr:first-child>th:nth-child(5) {
  text-align: center !important;
}

.audit-table .ant-table-tbody>tr>td {
  color: #262626;
  padding: 6px 16px;
  font-weight: 600;
  text-align: start;
}

.filter-container {
  margin-bottom: 12px;

  background-image: linear-gradient(#ccd4e0, #f0f2f5);
}

.filter-container-title {
  padding: 13px;
  font-weight: 700;
  color: #122144;
}

.summary-filters {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;

  padding: 13px 13px 0px 0px;
}

.summary .ant-select-selection {
  background-color: white;
  color: #4672ba;
  padding-left: 8px;
  font-size: 14px;
  width: 150px;
  border: 1px solid #d9d9d9;
}

.summary .ant-select-selection:hover {
  border: 0px solid #40a9ff;
}

.summary .ant-select-selection:focus {
  border: 0px solid #d9d9d9 !important;
}

.ant-calendar-picker {
  padding: 4px;
  width: 250px;
}

.ant-calendar-range-picker-input {
  width: 44%;
  color: #4672ba;
  font-weight: 500;
}

.audit-trail-text {
  cursor: pointer;
  text-decoration: underline !important;
}

.table-container.audit-table {
  height: 500px;
  overflow: scroll;
}

/* .summary .table-container {
  height: 80%;
  overflow: scroll;
} */

.audit-table .ant-table-thead>tr,
.audit-table .ant-table-tbody>tr {
  vertical-align: baseline;
  text-align: center;
}

.summary .ant-input-number-handler-wrap {
  display: none;
}

.summary .ant-input-number-input-wrap {
  width: 100%;
}

.summary .ant-table-tbody>tr>td:nth-child(8) {
  background-color: #f5f5f5;
}


.visited .ant-input-number {
  border: 1px solid #122144;
}

.summary .ant-input-number-input {
  color: #000000;
  width: 100%;
  font-weight: 600;
}

.summary .ant-input-number-input:focus {
  font-weight: 700;
}

.ant-drawer-body {
  padding: 7px !important;
}


.drawer-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  vertical-align: middle;
}

.drawer-text {
  font-size: 12px;
  padding-top: 6px;
  padding-left: 6px;
  color: #122144;
  font-weight: 700;
  margin-right: 10px;
}

.drawer-container .ant-btn {
  border-radius: 20px;
  background-color: #003285;
  color: white;
  font-size: 11px;
}

.summary .table-container .quantity-container .ant-input-number {
  width: 100%;
}

.table-container .ant-input-number-input,
.modal-table-container .ant-input-number-input {
  padding: 0px 5px 0 6px !important;
}

.disable-field {
  pointer-events: none;
  opacity: 0.7;
}

.filter-select .ant-calendar-picker-input.ant-input,
.filter-select .ant-calendar-range-picker-input,
.filter-select .ant-select-selection {
  border: none !important;
  color: #313131;
}

.filter-select .ant-calendar-picker-input.ant-input:focus {
  border: none;
  box-shadow: none;
}

.filter-select .ant-calendar-range-picker-input {
  margin-left: 0px !important;
}

.textCenter>span>div {
  text-align: center;
}

.summary .round-icon {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #004eff;
  margin: 6px 3px 0px 0px;
}

.summary .round-icon-disabled {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: transparent;
  margin: 6px 3px 0px 0px;
}

.competitor-hover-container .ant-table-bordered .ant-table-thead>tr>th,
.competitor-hover-container .ant-table-bordered .ant-table-tbody>tr>td {
  background: black;
  opacity: 0.7;
  color: white;
  padding: 0 6px;
}

.competitor-hover-container .ant-popover-title {
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  padding: 5px 16px 4px;
  color: white;
  font-weight: 500;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
  background: black;
  opacity: 0.7;
}

.competitor-hover-container .ant-popover-inner-content {
  padding: 6px 6px;
  background-color: transparent;
  padding: 0;

  border-radius: 3px;
}

.show-fav-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0px 10px 7px 0px;
  margin-top: -7px;
}