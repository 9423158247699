.approvals-container {
  display: flex;
  flex-direction: column;

  background-color: #f7f9fb;
}

.approvals-top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  vertical-align: middle;
}

.approvals-table-container {
  width: 99.4%;
  align-items: center;
}

.approvals-table-container .ant-table-thead>tr>th {
  padding: 6px 0px 16px 8px;
}

.approvals-table-container .ant-table-tbody>tr>td {
  padding: 6px 6px;
}

.approvals-table-container .ant-table-bordered .ant-table-tbody>tr>td:nth-child(11),
.approvals-table-container .ant-table-bordered .ant-table-tbody>tr>td:nth-child(12) {
  vertical-align: middle;
}

.approvals-container h1 {
  margin-top: 3px;
  font-size: 21px;
  font-weight: 100;
  max-height: 80px;
  cursor: default;
}

.approval-branch-select {
  display: flex;
}

.approvals-top-container .ant-select-selection {
  box-sizing: border-box;
  display: flex;
  background-color: transparent;
  border: 0px solid #d9d9d9;
  color: black;
  font-size: 21px;
  font-weight: 500;
}

.approvals-top-container .ant-select-selection:hover {
  background-color: transparent !important;
  border-color: transparent !important;
}

.approval-table-checkbox {
  display: flex;
  justify-content: space-around;
}

.approval-table-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #000000;
  border-color: #000000;
  border-radius: 10px;
  margin: 1px 0px 0 1px;
  width: 18px;
  height: 18px;
}

.approval-table-checkbox .ant-checkbox-inner {
  border: none;
  border-radius: 16px;
  width: 22px;
  height: 20px;
}

.ant-checkbox-checked::after {
  border: none;
}

.approval-table-checkbox .ant-checkbox {
  outline-color: transparent !important;
  border: 1px solid #e8e8e8;
  padding: 1px;
  outline-color: transparent !important;
  border-radius: 17px;
  overflow: hidden;
  width: 24px;
  height: 24px;
  background-color: white;
}

.approval-table-checkbox .ant-checkbox.ant-checkbox-checked {
  border: 1px solid black;
}

.approval-table-checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
  margin-left: 2px;
}

/* .approval-table-checkbox .ant-checkbox.ant-checkbox-checked:hover {
  border: none !important;
} */

.approval-table-checkbox .ant-checkbox-input:focus {
  border: none !important;
}

.approvals-container .anticon.anticon-filter.ant-table-filter-selected.ant-dropdown-trigger {
  color: white !important;
  margin-top: 7px;
  margin-right: 8px;
  height: 23px;
  border-radius: 10px;
  background-color: #558adf !important;
}

.approvals-container .ant-table-thead>tr>th .anticon-filter {
  color: #cdd6e5;
  height: 23px;
  margin-right: 8px;
  margin-top: 7px;
}

.approvals-table-container .ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-filters .anticon-filter.ant-table-filter-open,
.ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-filters .ant-table-filter-icon.ant-table-filter-open {
  background: #4672ba;
  color: #cdd6e5;
}

.approvals-table-container .ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:hover {
  background-color: transparent;
  color: #cdd6e5;
}

/* .anticon.anticon-filter.ant-dropdown-trigger:hover {
  background-color: #4672ba;
} */

.approvals-table-container .ant-checkbox+span {
  color: white;
  font-weight: 400;
  padding-right: 8px;
  padding-left: 8px;
}

/* DRAWER BUTTON STYLES */
.ant-btn.reject-button {
  background-color: #d53c3d !important;
  margin-right: 6px;
  width: 80px;
}

.ant-btn.approve-button {
  background-color: #5bc95a !important;
  width: 80px;
}

.approval-status {
  display: flex;
  justify-content: space-around;
  vertical-align: middle;
}

.approval-status-image {
  width: 24px;
  height: 24px;
}

/* .ant-popover-inner-content {
    padding: 6px 6px;
    background-color: #ffffff;
    color: #272727;
    border-radius: 3px;
} */

.popover-appoval .ant-popover-inner-content {
  padding: 6px 6px;
  background-color: #ffffff !important;
  color: #2d2d2d !important;
  border-radius: 3px;
}

.popover-appoval .ant-popover-content .ant-popover-arrow {
  border-top-color: transparent;
  border-right-color: #ffffff !important;
  border-bottom-color: #ffffff !important;
  border-left-color: #ffffff !important;
}

.popover-appoval .ant-popover-content .ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 5px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.3) !important;
}

/* MODAL REJECT */
.reject-comments-modal .ant-modal-content {
  border-radius: 15px;
  background-color: white;
}

.reject-comments-modal .ant-modal-header {
  color: rgb(255, 255, 255);
  background: #154479;
  border-radius: 15px 15px 0 0;
}

.reject-comments-modal .ant-modal-body {
  height: 213px;
}

.reject-comments-modal .ant-modal-header .ant-modal-title {
  color: rgb(255, 255, 255);
  font-weight: 400;
}

.reject-comments-modal .ant-modal-close-x {
  color: white;
}

.reject-comments-modal .ant-modal-footer {
  padding: 12px 25px;
}

.reject-comments-modal .ant-modal-footer div:first-child {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.reject-comments-modal .ant-modal-footer .ant-btn {
  /* .reject-comments-modal .ant-modal-footer .ant-btn:hover,
.reject-comments-modal .ant-modal-footer .ant-btn:active,
.reject-comments-modal .ant-modal-footer .ant-btn:focus { */
  background-color: #cbcbcb !important;
  width: 115px;
}

.reject-comments-modal .ant-modal-footer .ant-btn.ant-btn-primary {
  background-color: #154479 !important;
}

/* Custom footer for modal */
.modal-footer-container {
  margin: 19px 0px;
  display: flex;
  justify-content: space-between;
}

.ant-btn.submit-button-reject {
  width: 115px;
  color: white;
  background-color: #154479;
}

.ant-btn.cancel-button-reject {
  width: 115px;
  color: white;
  background-color: #cbcbcb;
}