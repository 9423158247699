.arrival-category-list-container {
  height: 635px  !important;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
}

.arrival-card-container .ant-btn {
  font-weight: 600;
  font-size: 13px;
  background-color: #4672ba;
  color: white;
  border-radius: 0 0 5px 5px;
  border: 0px solid transparent;
}

.bottom-container .ant-card-body {
  background-color: #f3f4f8;
  padding: 4px;
  border-radius: 0px 0px 0px 0px;
}

.locked {
  pointer-events: none;
  opacity: 0.6;
}