.priorityYes {
    color: rgb(116, 177, 46);
}

.priorityNo {
    color: rgb(255, 3, 26);
}

.remarks-select1 .ant-select {
    background-color: white;
    border: ridge;
}

.ant-input-disabled {
    color: black !important;
}

.master-form-container .ant-select-selection {
    box-sizing: border-box;
    display: flex;
    background-color: white;
    border: 0px solid #d9d9d9;
    color: black;
    font-size: 13px;
    font-weight: 500;
}

.master-form-container .ant-select-selection--single {
    box-sizing: border-box;
    background-color: white;
    border: 1px solid #d9d9d9;
    height: 30px;
}