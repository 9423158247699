.table-container {
  display: flex;
  width: 98.5%;
  flex-direction: column;
  justify-content: space-between;
  margin: 0px 3px 0px 9px;
  background-color: #e9eef2;
}

/* .table-container .ant-table-bordered .ant-table-thead > tr > th:nth-child(2),
.table-container .ant-table-bordered .ant-table-thead > tr > th:nth-child(3) {
  border-right: 2px solid #e8e8e8;
} */

/* .no-hover{
    pointer-events: none;
} */

.submit-btn {
  background-color: white !important;
  margin: 0px 0px !important;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 10px 0 rgba(0, 0, 0, 0.1);
}

.hub-table .ant-table {
  background-color: #eff3f6;
}

.col-container {
  font-size: 20px;
  font-weight: 200;
  line-height: 15px;
}

.bottom-container {
  display: flex;
  flex-direction: row;
}

.bottom-container h1 {
  margin-bottom: 0px;
  color: #eeeff2;
  font-weight: 500;
  font-size: 14px;
}

/* Custom table */

.hub-table .col-container {
  font-size: 20px;
  font-weight: normal;
  line-height: 15px;
}

.hub-table .ant-table-thead > tr,
.ant-table-tbody > tr {
  vertical-align: middle !important;
}

.hub-table .ant-table-thead > tr > th .ant-table-header-column {
  text-align: left !important;
}

.hub-table .ant-table-thead > tr > th {
  background: #0a123c;
  text-align: start;
  vertical-align: top;
}

.hub-table .ant-table-thead > tr > th .ant-table-header-column {
  font-weight: 400;
  font-size: 16px;
}

.hub-table .ant-table-thead > tr > th,
.hub-table .ant-table-tbody > tr > td {
  padding: 4px 16px;
}

.hub-table .ant-table-tbody > tr > td {
  font-weight: 600;
  color: #262626;
}

.ant-table-bordered .ant-table-tbody > tr > td {
  color: #262626;
  font-weight: 600;
}

.table-container .ant-input-number-input,
.modal-table-container .ant-input-number-input {
  color: #000000 !important;
  font-weight: 600;
}

.hub-table .ant-table-tbody > tr > td {
  padding: 4px 5px !important;
}

.hub-table .quantity-container .ant-input-number {
  font-size: 12px;
  color: black;
}

.hub-table .ant-table-thead > tr:first-child > th:first-child,
.hub-table .ant-table-thead > tr:nth-child(2) > th:first-child,
.hub-table .ant-table-thead > tr:nth-child(3) > th:first-child {
  background-color: #4672ba;
  border-right: 4px solid #e9eef2 !important;
  border-bottom: none;
}

.hub-table .ant-table-thead > tr:first-child > th:last-child {
  border-left: 0px solid #e9eef2;
  background-color: #4672ba;
  border-bottom: none;
}

/* .hub-table .ant-table-thead>tr:nth-child(1)>th:nth-child(6) {
  border-left     : 4px solid #e9eef2;
  background-color: #4672ba;
  border-bottom   : none;
} */

.hub-table .ant-table-thead > tr:nth-child(3) > th:first-child {
  background-color: #3c5a93;
}

.hub-table .ant-table-thead > tr:first-child > th:nth-child(2),
.hub-table .ant-table-thead > tr:first-child > th:nth-child(3),
.hub-table .ant-table-thead > tr:first-child > th:nth-child(4) {
  background-color: #030a30;
  margin-right: -10px;
  border-right: 4px solid #e9eef2;
  border-bottom: 1px solid #1f2649;
}

.hub-table .ant-table-thead > tr:first-child > th:nth-child(4) {
  background-color: #030a30;
  border-bottom: 1px solid #1f2649;
}

.hub-table .ant-table-thead > tr:nth-child(1) > th:nth-child(5),
.hub-table .ant-table-thead > tr:nth-child(2) > th:nth-child(5),
.hub-table .ant-table-thead > tr:nth-child(3) > th:nth-child(5),
.hub-table .ant-table-thead > tr:nth-child(2) > th:nth-child(6),
.hub-table .ant-table-thead > tr:nth-child(2) > th:nth-child(4),
.hub-table .ant-table-thead > tr:nth-child(2) > th:nth-child(2),
.hub-table .ant-table-thead > tr:nth-child(3) > th:nth-child(2),
.hub-table .ant-table-thead > tr:nth-child(2) > th:nth-child(11),
.hub-table .ant-table-thead > tr:nth-child(3) > th:nth-child(11),
.hub-table .ant-table-thead > tr:nth-child(3) > th:nth-child(4),
.hub-table .ant-table-thead > tr:nth-child(3) > th:nth-child(6) {
  border-right: 4px solid #e9eef2 !important;
}

.hub-table .ant-table-thead > tr:nth-child(1) > th:nth-child(5),
.hub-table .ant-table-thead > tr:nth-child(2) > th:nth-child(8) {
  border-bottom: 1px solid #1f2649;
}

.hub-table .ant-table-thead > tr:nth-child(2) > th:nth-child(5),
.hub-table .ant-table-thead > tr:nth-child(3) > th:nth-child(5) {
  border-right: 1px solid #1f2649;
}

.hub-table .ant-table-thead>tr:nth-child(2)>th:nth-child(1),
.hub-table .ant-table-thead>tr:nth-child(2)>th:nth-child(1),
/* .hub-table .ant-table-thead > tr:nth-child(2) > th:nth-child(4), */

.hub-table .ant-table-thead>tr:nth-child(2)>th:nth-child(5),
.hub-table .ant-table-thead>tr:nth-child(2)>th:nth-child(6),
.hub-table .ant-table-thead>tr:nth-child(2)>th:nth-child(8),
.hub-table .ant-table-thead>tr:nth-child(2)>th:nth-child(9),
.hub-table .ant-table-thead>tr:nth-child(2)>th:nth-child(10),
/* .hub-table .ant-table-thead>tr:nth-child(2)>th:nth-child(11), */
/* .hub-table .ant-table-thead > tr:nth-child(2) > th:nth-child(2), */

.hub-table .ant-table-thead>tr:nth-child(3)>th:nth-child(5),
.hub-table .ant-table-thead>tr:nth-child(3)>th:nth-child(6),
.hub-table .ant-table-thead>tr:nth-child(3)>th:nth-child(8),
.hub-table .ant-table-thead>tr:nth-child(3)>th:nth-child(9),
.hub-table .ant-table-thead>tr:nth-child(3)>th:nth-child(10),
/* .hub-table .ant-table-thead>tr:nth-child(3)>th:nth-child(11), */
.hub-table .ant-table-thead>tr:nth-child(2)>th:nth-child(12),
.hub-table .ant-table-thead>tr:nth-child(3)>th:nth-child(12) {
  border-right: 1px solid #1f2649;
  border-bottom: none;
}

.hub-table .ant-table-thead > tr:nth-child(2) > th:nth-child(4),
.hub-table .ant-table-thead > tr:nth-child(2) > th:nth-child(2),
.hub-table .ant-table-thead > tr:nth-child(2) > th:nth-child(3),
.hub-table .ant-table-thead > tr:nth-child(2) > th:nth-child(5),
.hub-table .ant-table-thead > tr:nth-child(2) > th:nth-child(13),
.hub-table .ant-table-thead > tr:nth-child(3) > th:nth-child(4),
.hub-table .ant-table-thead > tr:nth-child(3) > th:nth-child(5),
.hub-table .ant-table-thead > tr:nth-child(2) > th:nth-child(7),
.hub-table .ant-table-thead > tr:nth-child(3) > th:nth-child(7),
.hub-table .ant-table-thead > tr:nth-child(3) > th:nth-child(13) {
  border-bottom: none;
}

.hub-table .ant-table-thead>tr:nth-child(2)>th:nth-child(3),
.hub-table .ant-table-thead>tr:nth-child(3)>th:nth-child(3),
.hub-table .ant-table-thead>tr:nth-child(2)>th:nth-child(7),
.hub-table .ant-table-thead>tr:nth-child(3)>th:nth-child(7),
.hub-table .ant-table-thead>tr:nth-child(3)>th:nth-child(8),
.hub-table .ant-table-thead>tr:nth-child(3)>th:nth-child(9),
.hub-table .ant-table-thead>tr:nth-child(3)>th:nth-child(10),
/* .hub-table .ant-table-thead>tr:nth-child(3)>th:nth-child(11), */
.hub-table .ant-table-thead>tr:nth-child(3)>th:nth-child(12) {
  border-right: 1px solid #1f2649 !important;
}

.hub-table .ant-table-thead > tr:nth-child(3) > th {
  vertical-align: bottom;
}

.hub-table .ant-table-thead > tr:nth-child(2) > th:not(:first-child) {
  background-color: #030a30;
}

.hub-table .ant-table-thead > tr > th {
  padding: 4px 10px;
}

.select-remarks .ant-select-selection {
  border: 1px solid #d9d9d9 !important;
  color: black;
  font-size: 13px;
  word-wrap: break-word;
  width: 240px;
  padding: 0 4px;
}

.select-remarks .ant-select-selection-selected-value {
  margin-right: 1px;
  width: 240px;
}

.select-remarks .ant-select-selection:focus,
.select-remarks .ant-select-selection:active {
  box-shadow: none;
  border: 1px solid #d9d9d9 !important;
}

.ant-table-thead > tr > th {
  background: #4571ba;
  text-align: center;
}

.column-header-container {
  display: flex;
  flex-direction: column;
  margin: 0px 0px;
}

.combined-columns-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px 20px;
}

.ant-table {
  color: #1a3062;
  font-size: 12px;
  font-weight: 800;
}

.ant-table-thead > tr,
.ant-table-tbody > tr {
  vertical-align: baseline;
}

.ant-table-scroll {
  margin-right: -8px;
}

.top-slider-container {
  border: 2px solid #dbe2ea;
  margin: 0px auto 0px auto;
  border-radius: 67px;
  height: 33px;
  background: #eff3f6;
  font-weight: 700;
  color: #1a3062;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  vertical-align: middle;
  width: 270px;
  font-size: 14px;
}

.subtitle-info {
  margin-top: 10px;
  margin-bottom: 2px;
  color: #1a3062;
  font-weight: 500;
}

.top-slider-container .quantity-header-container {
  border-radius: 50px 0 0 50px;
  height: 29px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #dbe2ea;
  width: 51%;
  padding: 0 16px;
  text-align: center;
}

.top-slider-container .slider {
  margin-top: 4px;
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
}

/* .ant-modal-header {
  padding: 10px 24px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
}

.ant-modal-body {
  padding: 24px;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
} */

.ant-modal.mandi-modal-style .ant-modal-header {
  padding: 10px 24px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
}

.ant-modal.mandi-modal-style .ant-modal-body {
  padding: 24px;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
}

.ant-table-thead > tr > th .ant-table-header-column {
  color: white;
  width: 100%;
  font-size: 10px;
}

.ant-modal-centered .ant-modal {
  top: 0;
  padding-bottom: 0;
}

.ant-modal-content {
  background-color: #eff3f6;
}

/* custom modal table */

.modal-table-container .ant-table-bordered .ant-table-thead > tr > th {
  padding: 4px 10px;
  background-color: #0a123c !important;
  text-align: left !important;
  vertical-align: top;
}

.modal-table-container
  .ant-table-bordered
  .ant-table-thead
  > tr:nth-child(3)
  > th {
  vertical-align: bottom;
}

.modal-table-container
  .ant-table-bordered
  .ant-table-thead
  > tr:first-child
  > th:nth-child(2),
.modal-table-container
  .ant-table-bordered
  .ant-table-thead
  > tr:first-child
  > th:nth-child(3),
.modal-table-container
  .ant-table-bordered
  .ant-table-thead
  > tr:first-child
  > th:nth-child(4) {
  background-color: #040b31 !important;
  border-bottom: 1px solid #1f2649;
  border-right: none;
}

.modal-table-container
  .ant-table-bordered
  .ant-table-thead
  > tr:first-child
  > th:first-child,
.modal-table-container
  .ant-table-bordered
  .ant-table-thead
  > tr:first-child
  > th:last-child,
.modal-table-container
  .ant-table-bordered
  .ant-table-thead
  > tr:nth-child(2)
  > th:first-child,
.modal-table-container
  .ant-table-bordered
  .ant-table-thead
  > tr:nth-child(3)
  > th:first-child {
  background-color: #4672ba !important;
}

.modal-table-container
  .ant-table-bordered
  .ant-table-thead
  > tr:nth-child(3)
  > th:first-child {
  background-color: #3c5a93 !important;
}

.modal-table-container
  .ant-table-bordered
  .ant-table-thead
  > tr:first-child
  > th:nth-child(5),
.modal-table-container
  .ant-table-bordered
  .ant-table-tbody
  > tr
  > td:nth-child(13) {
  border-left: 4px solid #e9eef2;
}

/* .modal-table-container
  .ant-table-bordered
  .ant-table-thead
  > tr:nth-child(2)
  > th:nth-child(7),
.modal-table-container
  .ant-table-bordered
  .ant-table-tbody
  > tr
  > td:nth-child(11) {
  border-right: 0px solid;
} */

.modal-table-container .ant-table-bordered .ant-table-thead>tr:nth-child(2)>th:nth-child(2),
.modal-table-container .ant-table-bordered .ant-table-thead>tr:nth-child(2)>th:nth-child(3),
.modal-table-container .ant-table-bordered .ant-table-thead>tr:nth-child(2)>th:nth-child(5),
.modal-table-container .ant-table-bordered .ant-table-thead>tr:nth-child(2)>th:nth-child(6),
.modal-table-container .ant-table-bordered .ant-table-thead>tr:nth-child(2)>th:nth-child(7),
.modal-table-container .ant-table-bordered .ant-table-thead>tr:nth-child(2)>th:nth-child(8),
.modal-table-container .ant-table-bordered .ant-table-thead>tr:nth-child(2)>th:nth-child(9),
.modal-table-container .ant-table-bordered .ant-table-thead>tr:nth-child(2)>th:nth-child(10),
/* .modal-table-container .ant-table-bordered .ant-table-thead>tr:nth-child(2)>th:nth-child(11), */
.modal-table-container .ant-table-bordered .ant-table-thead>tr:nth-child(2)>th:nth-child(12),
.modal-table-container .ant-table-bordered .ant-table-thead>tr:nth-child(3)>th:nth-child(2),
.modal-table-container .ant-table-bordered .ant-table-thead>tr:nth-child(3)>th:nth-child(3),
.modal-table-container .ant-table-bordered .ant-table-thead>tr:nth-child(3)>th:nth-child(5),
.modal-table-container .ant-table-bordered .ant-table-thead>tr:nth-child(3)>th:nth-child(6),
.modal-table-container .ant-table-bordered .ant-table-thead>tr:nth-child(3)>th:nth-child(7),
.modal-table-container .ant-table-bordered .ant-table-thead>tr:nth-child(3)>th:nth-child(8),
.modal-table-container .ant-table-bordered .ant-table-thead>tr:nth-child(3)>th:nth-child(9),
.modal-table-container .ant-table-bordered .ant-table-thead>tr:nth-child(3)>th:nth-child(10),
/* .modal-table-container .ant-table-bordered .ant-table-thead>tr:nth-child(3)>th:nth-child(11), */
.modal-table-container .ant-table-bordered .ant-table-thead>tr:nth-child(3)>th:nth-child(12) {
  border-right: 1px solid #1f2649;
  border-bottom: none;
}

.modal-table-container
  .ant-table-bordered
  .ant-table-thead
  > tr:nth-child(2)
  > th:nth-child(3),
.modal-table-container
  .ant-table-bordered
  .ant-table-thead
  > tr:nth-child(2)
  > th:nth-child(6) {
  border-right: 4px solid #e9eef2;
}

.modal-table-container
  .ant-table-bordered
  .ant-table-thead
  > tr:nth-child(3)
  > th:nth-child(3),
.modal-table-container
  .ant-table-bordered
  .ant-table-thead
  > tr:nth-child(3)
  > th:nth-child(6) {
  border-bottom: none;
  border-right: 4px solid #e9eef2;
}

.modal-table-container
  .ant-table-bordered
  .ant-table-thead
  > tr:nth-child(2)
  > th:nth-child(4),
.modal-table-container
  .ant-table-bordered
  .ant-table-thead
  > tr:nth-child(2)
  > th:nth-child(5),
.modal-table-container
  .ant-table-bordered
  .ant-table-thead
  > tr:nth-child(2)
  > th:nth-child(11) {
  border-bottom: none;
}

.modal-table-container
  .ant-table-bordered
  .ant-table-thead
  > tr:first-child
  > th:first-child,
.modal-table-container
  .ant-table-bordered
  .ant-table-thead
  > tr:first-child
  > th:nth-child(2),
.modal-table-container
  .ant-table-bordered
  .ant-table-thead
  > tr:first-child
  > th:nth-child(3),
.modal-table-container
  .ant-table-bordered
  .ant-table-tbody
  > tr
  > td:nth-child(1),
.modal-table-container
  .ant-table-bordered
  .ant-table-tbody
  > tr
  > td:nth-child(3),
.modal-table-container
  .ant-table-bordered
  .ant-table-tbody
  > tr
  > td:nth-child(6) {
  border-right: 4px solid #e9eef2;
}

.modal-table-container .ant-table-thead > tr > th .ant-table-header-column {
  font-weight: 400;
  font-size: 16px;
}

.modal-table-container
  .ant-table-bordered
  .ant-table-thead
  > tr:nth-child(2)
  > th:nth-child(1) {
  border-right: 1px solid #1f2649;
}

.modal-table-container
  .ant-table-bordered
  .ant-table-thead
  > tr:nth-child(2)
  > th:nth-child(11) {
  border-right: none;
}

.modal-table-container
  .ant-table-bordered
  .ant-table-thead
  > tr:nth-child(2)
  > th:nth-child(4),
.modal-table-container
  .ant-table-bordered
  .ant-table-thead
  > tr:nth-child(3)
  > th:nth-child(4) {
  border-right: 1px solid #1f2649;
}

.modal-table-container
  .ant-table-bordered
  .ant-table-thead
  > tr:nth-child(1)
  > th:first-child,
.modal-table-container
  .ant-table-bordered
  .ant-table-thead
  > tr:nth-child(2)
  > th:first-child,
.modal-table-container
  .ant-table-bordered
  .ant-table-thead
  > tr:nth-child(3)
  > th:first-child {
  border-right: 4px solid #e9eef2;
  border-bottom: none;
}

.modal-table-container
  .ant-table-bordered
  .ant-table-thead
  > tr:nth-child(2)
  > th:not(:first-child) {
  background-color: #040b31 !important;
}

.modal-table-container .ant-table-wrapper {
  width: 100%;
}

/* mandi button styles */

.mandi-button {
  width: max-content;
  height: 0px;
  cursor: pointer;
  color: black;
  font-size: 18px;
  display: flex;
  flex-direction: row;
}

.mandi-button p {
  float: left;
  color: #040b31;
}

.mandi-button-container {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #040b31;
  color: white;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 28px;
}

/* FOR button styles */

.for-button {
  width: max-content;
  height: 0px;
  cursor: context-menu;
  color: black;
  font-size: 18px;
  display: flex;
  flex-direction: row;
}

.for-button-container {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #dadada;
  color: rgb(4, 11, 49);
  text-align: center;
  font-weight: 700;
  font-size: 13px;
  line-height: 28px;
}

.checkbox-container {
  overflow: hidden;
  width: 30px;
  margin: 17px 20px 0 0;
}

.quantity-container {
  display: flex;
  padding-top: 0px !important;
}

.quantity-container .ant-input-number {
}

.top-slider-container .action-item-container .action-button img {
  width: 30px;
  height: 30px;
}

.disabled-row td:not(:last-child) {
  background-color: #9e8686 !important;
  pointer-events: none;
}

/* .disabled-row {
  background-color: #c5c5c5 !important;
  pointer-events: none;
} */

.mandi-top-container {
  text-align: center;
  width: 100%;
}

.modal-table-container .ant-input-number {
  width: 78px;
  margin: 0px 0px 0 -10px;
}

.modal-table-container .ant-select {
  margin: 0 0 0 -9px;
}

.disable-field {
  pointer-events: none;
  opacity: 0.7;
}

.table-container .ant-input-number-input,
.modal-table-container .ant-input-number-input {
  padding: 0 0 0 2px;
  width: 111%;
  overflow: hidden;
  color: #1a3062;
}

.ant-popover-inner-content {
  padding: 6px 6px;
  background-color: #373e62;
  color: white;
  border-radius: 3px;
}

.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  border-top-color: transparent;
  border-right-color: #373e62;
  border-bottom-color: #373e62;
  border-left-color: #373e62;
}

.new-mandi-add-btn {
  margin-left: -1170px;
}

.new-mandi-add-btn .ant-btn {
  margin-bottom: 10px;
  background-color: #0b133d;
  color: white;
  height: 40px;
  border-radius: 20px;
}

.modal-table-container .new-mandi-select .ant-select-selection--single {
  box-sizing: border-box;
  width: 115px;
  background-color: transparent;
  border: 1px solid #d9d9d9;
}

.modal-table-container .new-mandi-select .ant-select-selection-selected-value {
  margin-right: 0px;
  font-size: 12px !important;
  margin-left: 6px;
}

.modal-table-container .new-mandi-select .ant-select-selection:active {
  border-right: 1px solid #d9d9d9 !important;
}

.new-mandi-add-btn .ant-btn[disabled]:hover {
  background-color: #d8d8d8;
  cursor: default;
  color: white;
}

.new-mandi-add-btn .ant-btn[disabled] {
  background-color: #d8d8d8;
  cursor: default;
  color: white;
}

.modal-title-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
