.arrival-card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 18px 25px;
  width: 280px;
}

.arrival-card-container-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.arrival-card-title {
  display: flex;
  padding: 10px 28px 0px 25px;
  flex-direction: row;
  justify-content: space-between;
  color: #1a3062;
  font-size: 12px;
  font-weight: 800;
  margin: 0px 0px 12px 5px;
}

.edit-button {
  font-weight: 700;
  display: flex;
  flex-direction: row;
}

.edit-button .edit-icon {
  width: 12px;
  height: 12px;
  margin: 3px 4px 0 0;
}

.edit-button:hover {
  cursor: pointer;
}

.arrival-card-container .ant-card-bordered {
  border: 0px solid #e8e8e8;
  border-radius: 5px;
}

.arrival-card-container .ant-card-body {
  padding: 0px 0px 6% 0px;
}

.arrival-card-container .remark-text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px 0px 12px 4px;
}

.remark-text-container .remark-title {
  font-size: 10px;
  font-weight: 400;
  color: #4f73b3;
  margin-left: 4px;
}

.remark-text-container .ant-input {
  border: none;
  border-bottom: solid 1px #fec045;
  border-radius: 0px;
  font-size: 14px;
  font-weight: 400;
  color: #1a3062;
  height: 20px;
  margin-left: 7px;
  width: 92%;
  padding: 0px !important;
}

.ant-btn {
  line-height: 1.499;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-color: blue;
  border: 0px solid transparent;
}

.remark-text-container .ant-input:focus {
  box-shadow: none;
}

.arrival-card-container .bottom-container {
  margin-top: -4px;
}

.arrival-card-container .bottom-container .ant-card-body {
  /* margin: 20px 0px 0px 11px; */
  background-color: #f3f4f8;
  padding: 4px;
  border-radius: 0px 0px 5px 5px;
}

.arrival-card-container .bottom-container .ant-card-bordered {

  border: 0px solid #e8e8e8;
  border-radius: 0px 0px 5px 5px;
  width: 100%;

}

.ant-input-number-input {
  font-weight: 700;
}

.min-max-field-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0% -4% 0% 11%;
}

.min-max-field-container h4 {
  margin: 2% 0% 0% -4%;
  font-style: italic;
  font-weight: 500;
  color: #1a3062;
}

.input-field-container .right-side-element {
  margin-left: 20%;
  margin-top: 3%;
}

.min-max-field-container .super-text-left {
  font-size: 9px;
  font-weight: 500;
  color: #4f73b3;
  position: absolute;
  margin: -6px 0px 1px 30px;
  z-index: 1;
}

.min-max-field-container .super-text-right {
  font-size: 9px;
  font-weight: 500;
  color: #4f73b3;
  position: absolute;
  margin: -6px 0px 1px 150px;
  z-index: 1;
}

.modal-price-min-max .ant-input-number-input {
  width: 100px;
}

.modal-price-min-max .input-field-container {
  width: 120px;
}

.modal-price-min-max .ant-input-number {
  width: 80px;
}

.quality-container .value-slider-component {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 250px;
  margin-left: 0px;
}