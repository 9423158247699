.projection-graph-container .graph-title {
  display: flex;
}

.projection-graph-container .mini-circle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  text-align: center;
  color: white;
  font-weight: 600;
  font-size: 10px;
  white-space: nowrap;
  margin: -10px 0 0 0;
}
