.arrival-card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 18px 25px;
  width: 280px;
}

.arrival-card-container-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.arrival-card-container .arrival-card-title {
  color: #1a3062;
  font-size: 12px;
  font-weight: 800;
  margin: 0px 0px 12px 5px;
}

.arrival-card-container .ant-card-bordered {
  border: 0px solid #e8e8e8;
  border-radius: 5px;
}

.arrival-card-container .ant-card-body {
  padding: 0px 0px 6% 0px;
}

.arrival-card-container .remark-text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px 0px 12px 4px;
}

.remark-text-container .remark-title {
  font-size: 10px;
  font-weight: 400;
  color: #4f73b3;
  margin-left: 4px;
}

.remark-text-container .ant-input {
  border: none;
  border-bottom: solid 1px #fec045;
  border-radius: 0px;
  font-size: 14px;
  font-weight: 400;
  color: #1a3062;
  height: 20px;
  margin-left: 7px;
  width: 92%;
  padding: 0px !important;
}

.remark-text-container .ant-input:focus {
  box-shadow: none;
}

.arrival-card-container .bottom-container {
  margin-top: -4px;
}

.arrival-card-container .bottom-container .ant-card-body {

  background-color: #f3f4f8;
  padding: 4px;
  border-radius: 0px 0px 0px 0px;
}

.ant-input-number-input {
  font-weight: 700;
}

.quality-container .value-slider-component {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 250px;
  margin-left: -12px;
}