.choose-hub-container {
  display: flex;
  justify-content: space-between;
  height: 60px;
  padding: 8px 15px;
}

.choose-hub-container h1 {
  margin-left: 10px !important;
  margin-top: 3px;
  font-size: 21px;
  font-weight: 100;
  max-height: 80px;
  cursor: default;
  white-space: nowrap !important;
}

.hub-selector .ant-select-selection {
  box-sizing: border-box;
  display: flex;
  background-color: transparent;
  border: 0px solid #d9d9d9;
  color: black;
  font-size: 21px;
  font-weight: 500;
}


.a-in-choosehub {
  margin: 10px;
}

.choose-hub-container a {
  padding: 0px;
}

.hub-selector {
  display: flex;
  justify-content: flex-start;
}

.add-hub-button.ant-btn {
  margin-bottom: 10px;
  background-color: #0b133d;
  color: white;
  height: 40px;
  border-radius: 20px;
}

.approval-checkbox {
  opacity: 0;
  font-size: 14px;
  color: black;
  font-weight: 400;
  margin: 9px 0px 0px 0px;
  pointer-events: none;
}

.choose-hub-container .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1a3062;
  border-color: #1a3062;
}

.add-hub-modal .ant-modal-content {
  width: 60%;
}

.add-hub-modal .ant-modal-close-x .anticon {
  color: white;
}

.add-hub-modal .ant-modal-header {
  background: #154479;
}

.add-hub-modal .ant-modal-title {
  color: white;
}

.add-hub-modal .ant-modal-footer {
  display: none;
}

.add-hub-modal .ant-modal-body {
  background-color: white;
  border-radius: 0 0 10px 10px;
  padding: 2px 24px 10px 24px;
}

.choose-hub-container .add-hub-disabled {
  pointer-events: none;
  opacity: 0;
}

.select-region .ant-select-selection__rendered {
  font-size: 12px;
  overflow-x: auto;
}

.select-varieties .ant-select-selection__rendered {
  font-size: 12px;
  overflow-x: auto;
}

.select-region {
  width: 320px !important;
}

.select-varieties {
  width: 320px !important;
}