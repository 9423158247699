.latest-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 98%;
  height: 82px;
  background-color: #f4f7fc;
  border-radius: 5px;
  margin: 5px 0 11px 0;
}

.latest-container .info-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 50%;

}

.info-left .main-info {
  display: flex;
  flex-direction: column;

  margin: 15px 20px 0 8px;
}

.latest-container .info-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
  margin: 18px 7px 0px 0;
  text-align: right;
  white-space: nowrap;
}

.latest-container .trend-image {
  width: 10px;
  margin: 0 0 0 8px;
}

.main-info .mandi {
  color: #8a90ac;
  font-size: 11px;
  font-weight: 500;
  margin: 5px 0 0px 0;
}

.main-info .variety {
  color: black;
  font-size: 13px;
  font-weight: 600;
  margin: 0px 0 5px 0;
}

.info-right .low-mandi {
  color: #f26061;
  font-size: 9px;
  font-weight: 600;

}

.info-right .high-mandi {
  color: #54c877;
  font-size: 9px;
  font-weight: 600;

}

.info-right .rate {
  color: black;
  font-size: 13px;
  font-weight: 600;
}

.latest-container .rotate-down {
  transform: rotate(180deg);
}