.wishlist-list .add-variety-button {
  display: flex;
  margin: 5px 0 5px 0;
  flex-direction: row;
  cursor: pointer;
  justify-content: flex-start;
}

.wishlist-list .add-variety-button .add-image {
  width: 17px;
  margin: 0 4px 0 6px;
}

.wishlist-list .add-variety-button .add-variety-text {
  color: #4670ba;
  font-size: 14px;
}

.wishlist-list .add-new-variety-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f4f7fc;
  border-radius: 5px;
}

.wishlist-list .add-new-variety-container .variety-select {
  margin: -3px 0 0px 8px;
}

.wishlist-list .add-new-variety-container .variety-select .ant-select-selection-selected-value {
  margin-right: 10px;
  font-size: 12px;
}

.wishlist-list .add-new-variety-container .variety-select .ant-select-search--inline .ant-select-search__field__wrap {
  width: 100%;
  height: 100%;
  font-size: 14px;
}

.wishlist-list .add-new-variety-container .add-variety-image {
  width: 18px;
  cursor: pointer;
  margin: 0 15px 0 0;
}

.wishlist-list .add-new-variety-container .variety-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}