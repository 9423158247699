.container-fluid {
  width: 100%;
  height: 100%;
  flex-directon: column;
  background-color: #ecedf3;
}

.flex-center {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.weather-container {
  display: flex;
  flex-direction: column;
}

.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* min-width: 310px; */
  /* max-width: 350px; */
  /* height: 50%; */
  border-radius: 2px;
  box-shadow: 0 0 5px 0 rgba(255, 255, 255, 0.3);
  overflow: hidden;
  position: relative;
}

.weather-container .background {
  z-index: -1;
  position: absolute;

  height: 110%;
  margin-top: -10px;
  margin-left: -10px;
}

.weather-container .background img {
  min-height: 100%;
  min-width: 100%;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
}

.weather-container .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 2;
  color: #fff;
  font-size: 1em;
  padding: 0px 15px 0px 15px;
  box-shadow: 0 0px 7px 1px rgba(0, 0, 0, 0.7);
  background-color: rgba(26, 37, 57, 0.6);
}
.weather-container .header .location {
  display: flex;
  flex: 10;
  justify-content: flex-start;
}
.weather-container .header .settingsBtn {
  display: flex;
  flex: 2;
  justify-content: flex-end;
  text-decoration: none;
  color: white;
  font-weigt: bold;
}

.weather-container .header .settingsBtn .active {
  opacity: 1;
}

.weather-container .header .settingsBtn .inactive {
  opacity: 0.5;
}

.weather-container .body {
  flex: 9 1;
  background-color: rgb(255, 255, 255);
  color: #2a2a2a;
}

.weather-container .body .display {
  display: flex;
  height: 100%;
  position: relative;
  color: #2a2a2a;
}

.weather-container .body .display .icon-container {
  flex: 1 1;
  height: 175px;
  width: 100%;
  /* overflow: hidden; */
  display: flex;
  transform: scale(0.9);
  position: relative;
  opacity: 0.8;
  margin-top: -40px;
  margin-left: -86px;
}

.weather-container .body .info {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.weather-container .body .info span {
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
}

.weather-container .body .info .time {
  flex: 5;
  font-size: 3em;
  align-items: flex-start;
}
.weather-container .body .info .date {
  flex: 1;
  align-items: flex-end;
  font-size: 0.9em;
}
.weather-container .body .info .temperature {
  flex: 5;
  align-items: flex-end;
  font-size: 3.5em;
}
.weather-container .body .info .conditions {
  flex: 1;
  align-items: flex-start;
  font-size: 0.9em;
}

.weather-container .footer {
  display: flex;
  flex-direction: row;
  flex: 1;
  -ms-flex-align: center;
  align-items: center;
  padding: 0px 15px 0px 15px;
  font-size: 0.8em;
  background-color: rgba(26, 37, 57, 0.9);
  color: white;
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.3);
}

.weather-container .footer a {
  flex: 6;
  text-align: right;
  text-decoration: none;
  color: white;
  float: right;
}

.weather-container .footer a:first-child {
  text-align: left;
}

.weather-container .loadingWeather {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  overflowe: hidden;
  width: 100%;
  height: 100%;
  background-color: rgb(26, 37, 57);
  z-index: 9999;
}

/*Loader animation from : https://projects.lukehaas.me/css-loaders/ */
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #ffffff;
  font-size: 10px;
  margin: 80px auto;
  margin-top: -0.3em;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

/* Effects */

/* Icons */
/* SUNNY */

.sunny {
  zoom: 0.25;
  animation: sunny 15s linear infinite;
  height: 140px;
  width: 20px;
  position: absolute;
  /* left: 126px; */
  top: 53px;
  margin-left: 61px;
}
.sunny::before {
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  height: 140px;
  width: 20px;
  opacity: 1;
  position: absolute;
  bottom: 0px;
  left: 0px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sunny::after {
  background: #ffee44;
  border-radius: 50%;
  box-shadow: rgba(255, 255, 0, 0.2) 0 0 0 15px;
  content: "";
  height: 80px;
  width: 80px;
  position: absolute;
  left: -30px;
  top: 30px;
}
@keyframes sunny {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* CLOUDY */
.cloudy {
  zoom: 0.3;
  animation: cloudy 5s ease-in-out infinite;
  background: #f0f0f0;
  border-radius: 50%;
  box-shadow: #ffeb3b 65px -15px 0 -5px, #f0f0f0 25px -25px, #f0f0f0 30px 10px,
    #f0f0f0 60px 15px 0 -10px, #f0f0f0 85px 5px 0 -5px;
  height: 50px;
  width: 50px;
  position: absolute;
  top: 70px;
}
.cloudy::after {
  -webkit-animation: cloudy_shadow 5s ease-in-out infinite;
  animation: cloudy_shadow 5s ease-in-out infinite;
  background: #000000;
  border-radius: 50%;
  content: "";
  height: 15px;
  width: 120px;
  opacity: 0.2;
  position: absolute;
  left: 5px;
  bottom: -60px;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
}
@keyframes cloudy {
  50% {
    transform: translateY(-20px);
  }
}
@keyframes cloudy_shadow {
  50% {
    transform: translateY(20px) scale(1);
    opacity: 0.05;
  }
}

/* RAINY */
.rainy {
  zoom: 0.25;
  animation: rainy 5s ease-in-out infinite 1s;
  background: #cccccc;
  border-radius: 50%;
  box-shadow: #cccccc 65px -15px 0 -5px, #cccccc 25px -25px, #cccccc 30px 10px,
    #cccccc 60px 15px 0 -10px, #cccccc 85px 5px 0 -5px;
  display: block;
  height: 50px;
  width: 50px;
  position: absolute;
  top: 79px;
}
.rainy::after {
  -webkit-animation: rainy_shadow 5s ease-in-out infinite 1s;
  animation: rainy_shadow 5s ease-in-out infinite 1s;
  background: #000000;
  border-radius: 50%;
  content: "";
  height: 15px;
  width: 120px;
  opacity: 0.2;
  position: absolute;
  left: 5px;
  bottom: -60px;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
}
.rainy::before {
  -webkit-animation: rainy_rain 0.7s infinite linear;
  animation: rainy_rain 0.7s infinite linear;
  content: "";
  background: #cccccc;
  border-radius: 50%;
  display: block;
  height: 6px;
  width: 3px;
  opacity: 0.3;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}
@keyframes rainy {
  50% {
    transform: translateY(-20px);
  }
}
@keyframes rainy_shadow {
  50% {
    transform: translateY(20px) scale(1);
    opacity: 0.05;
  }
}
@keyframes rainy_rain {
  0% {
    box-shadow: rgba(0, 0, 0, 0) 30px 30px, rgba(0, 0, 0, 0) 40px 40px,
      #000 50px 75px, #000 55px 50px, #000 70px 100px, #000 80px 95px,
      #000 110px 45px, #000 90px 35px;
  }
  25% {
    box-shadow: #000 30px 45px, #000 40px 60px, #000 50px 90px, #000 55px 65px,
      rgba(0, 0, 0, 0) 70px 120px, rgba(0, 0, 0, 0) 80px 120px, #000 110px 70px,
      #000 90px 60px;
  }
  26% {
    box-shadow: #000 30px 45px, #000 40px 60px, #000 50px 90px, #000 55px 65px,
      rgba(0, 0, 0, 0) 70px 40px, rgba(0, 0, 0, 0) 80px 20px, #000 110px 70px,
      #000 90px 60px;
  }
  50% {
    box-shadow: #000 30px 70px, #000 40px 80px, rgba(0, 0, 0, 0) 50px 100px,
      #000 55px 80px, #000 70px 60px, #000 80px 45px, #000 110px 95px,
      #000 90px 85px;
  }
  51% {
    box-shadow: #000 30px 70px, #000 40px 80px, rgba(0, 0, 0, 0) 50px 45px,
      #000 55px 80px, #000 70px 60px, #000 80px 45px, #000 110px 95px,
      #000 90px 85px;
  }
  75% {
    box-shadow: #000 30px 95px, #000 40px 100px, #000 50px 60px,
      rgba(0, 0, 0, 0) 55px 95px, #000 70px 80px, #000 80px 70px,
      rgba(0, 0, 0, 0) 110px 120px, rgba(0, 0, 0, 0) 90px 110px;
  }
  76% {
    box-shadow: #000 30px 95px, #000 40px 100px, #000 50px 60px,
      rgba(0, 0, 0, 0) 55px 35px, #000 70px 80px, #000 80px 70px,
      rgba(0, 0, 0, 0) 110px 25px, rgba(0, 0, 0, 0) 90px 15px;
  }
  100% {
    box-shadow: rgba(0, 0, 0, 0) 30px 120px, rgba(0, 0, 0, 0) 40px 120px,
      #000 50px 75px, #000 55px 50px, #000 70px 100px, #000 80px 95px,
      #000 110px 45px, #000 90px 35px;
  }
}

/* RAINBOW */
.rainbow {
  zoom: 0.25;
  animation: rainbow 5s ease-in-out infinite;
  border-radius: 170px 0 0 0;
  box-shadow: #fb323c -2px -2px 0 1px, #f99716 -4px -4px 0 3px,
    #fee124 -6px -6px 0 5px, #afdf2e -8px -8px 0 7px, #6ad7f8 -10px -10px 0 9px,
    #60b1f5 -12px -12px 0 11px, #a3459b -14px -14px 0 13px;
  height: 70px;
  width: 70px;
  position: absolute;
  top: 71px;
  margin-left: 30px;
  transform: rotate(40deg);
}
.rainbow:after {
  animation: rainbow_shadow 5s ease-in-out infinite;
  background: #000000;
  border-radius: 50%;
  content: "";
  opacity: 0.2;
  height: 15px;
  width: 120px;
  position: absolute;
  bottom: -23px;
  left: 17px;
  transform: rotate(-40deg);
  transform-origin: 50% 50%;
}
@keyframes rainbow {
  50% {
    transform: rotate(50deg);
  }
}
@keyframes rainbow_shadow {
  50% {
    transform: rotate(-50deg) translate(10px) scale(0.7);
    opacity: 0.05;
  }
}

/* STARRY */
.starry {
  zoom: 0.2;
  /* animation: starry_star 5s ease-in-out infinite; */
  /* background: #000; */
  border-radius: 50%;
  /* box-shadow: #ffffff 26px 7px 0 -1px, rgba(255, 255, 255, 0.1) -36px -19px 0 -1px, rgba(255, 255, 255, 0.1) -51px -34px 0 -1px, #ffffff -52px -62px 0 -1px, #ffffff 14px -37px, rgba(255, 255, 255, 0.1) 41px -19px, #ffffff 34px -50px, rgba(255, 255, 255, 0.1) 14px -71px 0 -1px, #ffffff 64px -21px 0 -1px, rgba(255, 255, 255, 0.1) 32px -85px 0 -1px, #ffffff 64px -90px, rgba(255, 255, 255, 0.1) 60px -67px 0 -1px, #ffffff 34px -127px, rgba(255, 255, 255, 0.1) -26px -103px 0 -1px; */
  height: 4px;
  width: 4px;
  margin-left: 246px;
  opacity: 1;
  position: absolute;
  top: 215px;
}

.starry::after {
  /* animation: starry 5s ease-in-out infinite; */
  border-radius: 50%;
  box-shadow: #000000 -25px 0;
  content: "";
  height: 100px;
  width: 100px;
  position: absolute;
  top: -106px;
  transform: rotate(-27deg);
  transform-origin: 0 50%;
  left: -169px;
}
@keyframes starry {
  50% {
    transform: rotate(10deg);
  }
}
@keyframes starry_star {
  50% {
    box-shadow: rgba(255, 255, 255, 0.1) 26px 7px 0 -1px,
      #ffffff -36px -19px 0 -1px, #ffffff -51px -34px 0 -1px,
      rgba(255, 255, 255, 0.1) -52px -62px 0 -1px,
      rgba(255, 255, 255, 0.1) 14px -37px, #ffffff 41px -19px,
      rgba(255, 255, 255, 0.1) 34px -50px, #ffffff 14px -71px 0 -1px,
      rgba(255, 255, 255, 0.1) 64px -21px 0 -1px, #ffffff 32px -85px 0 -1px,
      rgba(255, 255, 255, 0.1) 64px -90px, #ffffff 60px -67px 0 -1px,
      rgba(255, 255, 255, 0.1) 34px -127px, #ffffff -26px -103px 0 -1px;
  }
}

/* STORMY */
.stormy {
  zoom: 0.3;
  animation: stormy 5s ease-in-out infinite;
  background: #222222;
  border-radius: 50%;
  box-shadow: #222222 65px -15px 0 -5px, #222222 25px -25px, #222222 30px 10px,
    #222222 60px 15px 0 -10px, #222222 85px 5px 0 -5px;
  height: 50px;
  width: 50px;
  position: absolute;
  top: 70px;
}
.stormy:after {
  animation: stormy_shadow 5s ease-in-out infinite;
  background: #000;
  border-radius: 50%;
  content: "";
  height: 15px;
  width: 120px;
  opacity: 0.2;
  position: absolute;
  left: 5px;
  bottom: -60px;
  transform: scale(0.7);
}
.stormy:before {
  animation: stormy_thunder 2s steps(1, end) infinite;
  border-left: 0px solid transparent;
  border-right: 7px solid transparent;
  border-top: 43px solid yellow;
  box-shadow: yellow -7px -32px;
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  left: 57px;
  top: 70px;
  transform: rotate(14deg);
  transform-origin: 50% -60px;
}
@keyframes stormy {
  50% {
    transform: translateY(-20px);
  }
}
@keyframes stormy_shadow {
  50% {
    transform: translateY(20px) scale(1);
    opacity: 0.05;
  }
}
@keyframes stormy_thunder {
  0% {
    transform: rotate(20deg);
    opacity: 1;
  }
  5% {
    transform: rotate(-34deg);
    opacity: 1;
  }
  10% {
    transform: rotate(0deg);
    opacity: 1;
  }
  15% {
    transform: rotate(-34deg);
    opacity: 0;
  }
}

/* SNOWY */
.snowy {
  animation: snowy 5s ease-in-out infinite 1s;
  background: #ffffff;
  border-radius: 50%;
  box-shadow: #ffffff 65px -15px 0 -5px, #ffffff 25px -25px, #ffffff 30px 10px,
    #ffffff 60px 15px 0 -10px, #ffffff 85px 5px 0 -5px;
  display: block;
  height: 50px;
  width: 50px;
  position: absolute;
  top: 70px;
}
.snowy:after {
  animation: snowy_shadow 5s ease-in-out infinite 1s;
  background: #000000;
  border-radius: 50%;
  content: "";
  height: 15px;
  width: 120px;
  opacity: 0.2;
  position: absolute;
  left: 8px;
  bottom: -60px;
  transform: scale(0.7);
}
.snowy:before {
  animation: snowy_snow 2s infinite linear;
  content: "";
  border-radius: 50%;
  display: block;
  height: 7px;
  width: 7px;
  opacity: 0.8;
  transform: scale(0.9);
}
@keyframes snowy {
  50% {
    transform: translateY(-20px);
  }
}
@keyframes snowy_shadow {
  50% {
    transform: translateY(20px) scale(1);
    opacity: 0.05;
  }
}
@keyframes snowy_snow {
  0% {
    box-shadow: rgba(238, 238, 238, 0) 30px 30px,
      rgba(238, 238, 238, 0) 40px 40px, #eeeeee 50px 75px, #eeeeee 55px 50px,
      #eeeeee 70px 100px, #eeeeee 80px 95px, #eeeeee 110px 45px,
      #eeeeee 90px 35px;
  }
  25% {
    box-shadow: #eeeeee 30px 45px, #eeeeee 40px 60px, #eeeeee 50px 90px,
      #eeeeee 55px 65px, rgba(238, 238, 238, 0) 70px 120px,
      rgba(238, 238, 238, 0) 80px 120px, #eeeeee 110px 70px, #eeeeee 90px 60px;
  }
  26% {
    box-shadow: #eeeeee 30px 45px, #eeeeee 40px 60px, #eeeeee 50px 90px,
      #eeeeee 55px 65px, rgba(238, 238, 238, 0) 70px 40px,
      rgba(238, 238, 238, 0) 80px 20px, #eeeeee 110px 70px, #eeeeee 90px 60px;
  }
  50% {
    box-shadow: #eeeeee 30px 70px, #eeeeee 40px 80px,
      rgba(238, 238, 238, 0) 50px 100px, #eeeeee 55px 80px, #eeeeee 70px 60px,
      #eeeeee 80px 45px, #eeeeee 110px 95px, #eeeeee 90px 85px;
  }
  51% {
    box-shadow: #eeeeee 30px 70px, #eeeeee 40px 80px,
      rgba(238, 238, 238, 0) 50px 45px, #eeeeee 55px 80px, #eeeeee 70px 60px,
      #eeeeee 80px 45px, #eeeeee 110px 95px, #eeeeee 90px 85px;
  }
  75% {
    box-shadow: #eeeeee 30px 95px, #eeeeee 40px 100px, #eeeeee 50px 60px,
      rgba(238, 238, 238, 0) 55px 95px, #eeeeee 70px 80px, #eeeeee 80px 70px,
      rgba(238, 238, 238, 0) 110px 120px, rgba(238, 238, 238, 0) 90px 110px;
  }
  76% {
    box-shadow: #eeeeee 30px 95px, #eeeeee 40px 100px, #eeeeee 50px 60px,
      rgba(238, 238, 238, 0) 55px 35px, #eeeeee 70px 80px, #eeeeee 80px 70px,
      rgba(238, 238, 238, 0) 110px 25px, rgba(238, 238, 238, 0) 90px 15px;
  }
  100% {
    box-shadow: rgba(238, 238, 238, 0) 30px 120px,
      rgba(238, 238, 238, 0) 40px 120px, #eeeeee 50px 75px, #eeeeee 55px 50px,
      #eeeeee 70px 100px, #eeeeee 80px 95px, #eeeeee 110px 45px,
      #eeeeee 90px 35px;
  }
}

/* MINE */
.main-w-body-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: max-content;
  margin-top: 8px;
  /* transition: all 0.2s ease-in-out; */
}
/* .main-w-body-container:hover {
  transform: scale(0.98);
} */

.w-left-container {
  display: flex;
  color: #333333;
  /* flex-direction: column; */
}
.w-right-container {
  display: flex;
  /* flex-direction: column; */
}
.w-temperature {
  font-size: 21px;
  margin-right: 8px;
  margin-top: -1px;
}

.w-icon-container {
  margin: 0px 6px;
  color: #222222;
}

/* Forecast tooltip */
.ant-tooltip.forecast-tooltip {
}

.ant-tooltip.forecast-tooltip .ant-tooltip-inner {
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
}

.ant-tooltip.forecast-tooltip .ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  border-bottom-color: rgb(255, 255, 255);
}

.ant-tooltip.forecast-tooltip .ant-tooltip-arrow::before {
  background-color: rgb(255, 255, 255);
  content: "";
}
