.priorityYes {
    color: rgb(116, 177, 46);
}

.priorityNo {
    color: rgb(255, 3, 26);
}


.add-master-modal .ant-modal-body {
    overflow: auto;
    height: 400px;
}

.ant-input-disabled {
    color: black !important;
}

.master-form-container .ant-select-selection {
    box-sizing: border-box;

    display: flex;
    height: 30px;
    border: gray;
    background-color: white;
    border: 0px solid #d9d9d9;
    color: black;
    font-size: 13px;
    font-weight: 500;
}

.master-form-container .ant-select-selection--single {
    box-sizing: border-box;
    background-color: white;
    border: 1px solid #d9d9d9;
    height: 30px;
}

.hub-master-container .top-container .hub-region-filter {
    display: flex;
    margin-left: 25%;
}