.fixed-state-variety-projection-container {
  /* border: 1px solid gray; */
  /* border-radius: 10px; */
  /* background: white; */
  margin: 10px;
}
.fixed-state-variety-projection-container .projection-graph-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.fixed-state-variety-projection-container
  .projection-graph-container
  .graph-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 28px 10px;
  border: 1px solid #808080ab;
  border-radius: 5px;
  box-shadow: 1px 2px 5px 0px;
  background: white;
}
.fixed-state-variety-projection-container
  .projection-graph-container
  .graph-info
  .graph-title {
  font-size: 15px;
  font-weight: 700;
  color: #4672ba;
  margin: 18px 0 0 0;
}
.fixed-state-variety-projection-container
  .projection-graph-container
  .graph-info
  .graph {
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 250px;
  align-items: center;
  margin: 0px 0 0 0; */
}

.fixed-state-variety-projection-container .legend-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.fixed-state-variety-projection-container .legend-container .legend-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px;
}

.fixed-state-variety-projection-container
  .legend-container
  .legend-box
  .legend-fill {
  width: 30px;
  height: 15px;
}
.fixed-state-variety-projection-container
  .legend-container
  .legend-box
  .legend-title {
  font-weight: 600;
  font-weight: 600;
  margin: -4px 0px 0 7px;
}
