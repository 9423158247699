.volume-buying-plan-state-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.volume-buying-plan-state-container .normal-table {
  margin: 10px 0 45px 0;
}

.volume-buying-plan-state-container .normal-table .column {
  border: 1px solid white;
}

.volume-buying-plan-state-container .normal-table .total-row {
  background: #8bb0ef;
  /* border: 1px solid #82e24b; */
}

.volume-buying-plan-state-container .filters {
  display: flex;
  border-radius: 5px;
  background: white;
  margin: 10px 0 0 0;
}
