.qc-input-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.qc-input-page-container .filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: white;
  margin: 10px;
}

.qc-input-page-container .title {
  font-size: 15px;
  font-weight: 700;
  color: #4672ba;
  margin: 18px 0 0 0;
}

.qc-input-page-container .ant-btn {
  background-color: #154479 !important;
}
.qc-input-page-container .ant-btn:hover {
  background-color: #154479 !important;
}

.qc-input-page-container .refresh-button {
  background: #7eb5f3 !important;
  width: 75px;
  height: 75px;
  border-radius: 50px;
  color: white;
  white-space: normal;
  font-weight: 500;
  font-size: 11px;
  padding: 0 0px 0 0px;
  margin: 10px;
}
