.competitor-item-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.competitor-item-container .competitor-name-input {
  margin-top: 3px;
  width: 100%;
  display: flex;
}

.competitor-item-container .competitor-expand-btn {
  margin-top: 11px;
  font-size: 24px;
  padding: 0px 7px 0px 5px;
  line-height: 18px;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  cursor: pointer;
  color: #000000d1;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* .competitor-item-container .competitor-name-input .ant-input { */
.competitor-item-container .competitor-name-input .ant-select {
  margin-top: 10px;
  border: none;
  border-bottom: solid 1px #fec045;
  border-radius: 0px;
  font-size: 13px;
  font-weight: 400;
  color: #1a3062;
  height: 30px;
  /* 20px for ant input */

  width: 92%;
  padding: 0px !important;
  background-color: #f3f4f8;
}

.competitor-item-container .competitor-name-input .ant-select-selection {
  background-color: white;
  color: #4672ba;
  padding-left: 8px;
  font-size: 14px;

  border: 0px solid #d9d9d9;
}

.competitor-item-container .competitor-name-input .ant-select:focus {
  box-shadow: none;
}

.competitor-item-container .competitor-name-input .ant-select::placeholder {
  font-size: 12px;
  font-weight: 400;
  color: #4f73b3;
}

.competitor-item-container .icon-title-container {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-left: -1%;
  margin-top: 1%;
}

.arrival-card-container .bottom-container .ant-card-body {

  background-color: #f3f4f8;
  padding: 4px 4px 4px 16px;
}

.competitor-item-container .icon-title-container .icon-text {
  margin: 5% 0%;
}

.competitor-item-container .min-max-field-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0% -4% 0% 11%;
}

.competitor-item-container .ant-input-number {
  background-color: #f3f4f8;
  width: 75%;
}

/* .competitor-item-container .min-max-field-container h4 {
  margin: 2% 0% 0% -4%;
  font-style: italic;
  font-weight: 500;
  color: #1a3062;
} */

.min-max-field-container .super-text-left h4 {
  color: #1a3062 !important;
}

.competitor-min-max {
  width: 89%;
  margin-left: 28px;
  font-style: italic;
  color: #1a3062;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.competitor-item-container .ant-input-number-input {
  padding: 0%;
  width: 85% !important;
}

.competitor-item-container .input-field-container .right-side-element {

  margin-top: 3%;
  position: inherit;
  margin-left: -57px;
  z-index: 1;
}

.competitor-item-container .min-max-field-container .super-text-left {
  font-size: 9px;
  font-weight: 500;
  color: #4f73b3;
  position: inherit;
  margin: -8px 0px 1px 20px !important;
  z-index: 1;
}

.competitor-item-container .min-max-field-container .super-text-right {
  font-size: 9px;
  font-weight: 500;
  color: #4f73b3;
  position: absolute;
  margin: -6px 0px 1px 138px;
  z-index: 1;
}

.competitor-item-container .input-field-container {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-around !important;
  margin-right: 0% !important;
  margin-left: -34px;
}

.competitor-item-container .purchase-plan-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 0px;
}

.competitor-item-container .purchase-plan-container .purchase-plan-input {

  margin: 4% 0% 0% 24%;
}

.competitor-item-container .quality-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: -8px 0px 0px -4px;
}

.arrival-card-container .bottom-container .ant-card-body {

  background-color: #f3f4f8;
  padding: 4px;
  border-radius: 0px 0px 0px 0px;
}

.quality-container .icon-text {
  font-size: 12px;
  font-weight: bold;
  color: #1a3062;
  margin: 7px 0px 0px 0px !important;
}

.quality-container {
  margin-top: -18px;
}

/* Multiple competitor */

.competitor-multiple-container {
  height: 184px;
  overflow: scroll;
  overflow-x: hidden;
}

.plus-icon-comp {
  z-index: 1;
  margin-top: -18px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #1a3062;
  padding: 0px 0px 0px 6px;
  font-size: 20px;
  color: white;
  line-height: 20px;
  cursor: pointer;
  position: absolute;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.icon-container-comp {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0px 20px;
}

.competitor-item-container::after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.068);
  padding: 0px 10px;
}

.static-name-comp {
  color: #1a3062;
  font-size: 12px;
  font-weight: 500;
  padding-top: 11px;
  cursor: pointer;
}

.single-comp-container {
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
  width: 272px;
  height: 44px;
  margin-top: -6px;
}