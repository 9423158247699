.trigger {
  margin: 10px;
}

.icon-container {
  display: flex;
  justify-content: flex-end;
}

.icon-background {
  background-color: #1a3163;
}

.menu-icon {
  width: 25px;
  height: 25px;
}

.toggle-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}