.qc-input-form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: white;
  width: 90%;
}

.qc-input-form-container .row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* align-items: center; */
  margin: 10px;
}
.qc-input-form-container .column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.qc-input-form-container .top {
  justify-content: flex-start !important;
}

.qc-input-form-container .ant-input-number {
  width: 266px !important;
}

.qc-input-form-container .submit-button {
  margin: 10px;
}

.qc-input-form-container .clear {
  color: white;
  background: darkgrey !important;
}
