.summary-filters1 {
  display: flex;
  width: 391px;
  flex-direction: column;
  justify-content: space-between;
  padding: 13px 13px 0px 0px;
}

.filter-select1 {
  border-radius: 8px;
  padding: 0px 2px 0px 0px;
  background-color: white;
  border: 1px solid rgb(221, 221, 221);
  box-shadow: 2px 2px 6px -6px black;
}

.ant-tabs-nav-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.filter-select-master {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  width: 250px;
  margin-top: 7px;
  height: 30px;
}

.top1-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 98.5%;
  margin: 7px 3px 7px 9px;
}

.Master-tabs .ant-select-selection {
  background-color: white;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  align-items: flex-end;
  color: #646665;
  border: 1px solid rgba(128, 128, 128, 0.281);
  width: 100%;
  height: 100%;
  overflow: auto;
  margin: 0 0px 0 5px;
}

.Master-tabs .ant-select-selection:hover {
  background-color: white;
}

.Master-tabs .ant-select {
  padding-top: 0px;
  width: 95%;
}