.selector-group {
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: white;
}

.selector-group .ant-tabs-nav-wrap {
  background: transparent;
}

.selector-group .ant-tabs-tab {
  background-color: white;
  text-align: center;
  color: rgb(26, 26, 26);
}
.selector-group .ant-tabs-bar {
  border-bottom: none;
}

.selector-item .ant-tabs-tab:nth-child(1) {
  border-radius: 13px 0 0px 13px;
  border-top: 1px solid #a0a0a0;
  border-left: 1px solid #a0a0a0;
  border-bottom: 1px solid #a0a0a0;
}
.selector-item .ant-tabs-tab:nth-child(2) {
  border-radius: 0px 13px 13px 0px;
  border-top: 1px solid #a0a0a0;
  border-right: 1px solid #a0a0a0;
  border-bottom: 1px solid #a0a0a0;
}
.selector-item .ant-tabs-tab-active:nth-child(1) {
  border-top: 1px solid #040c30;
  border-left: 1px solid #040c30;
  border-right: 1px solid #040c30;
  border-bottom: 1px solid #040c30;
}
.selector-item .ant-tabs-tab-active:nth-child(2) {
  border-top: 1px solid #040c30;
  border-right: 1px solid #040c30;
  border-left: 1px solid #040c30;
  border-bottom: 1px solid #040c30;
}

.selector-group .ant-tabs-tab-active {
  background-color: #4670ba;
 
  color: white;
}
.ant-tabs-nav .ant-tabs-tab:hover {
  color: #262626;
}
.selector-group .ant-tabs-tab-active:hover {
  color: white;
}

.selector-group .ant-tabs-nav-scroll {
  background: transparent;
}
.selector-group .ant-tabs {
  margin: 25px 10px 0px 10px;
  width: 100%;
}
.selector-group .ant-tabs-nav-wrap {
  width: 89%;
  margin: 13px auto 0 auto;
 
}

/* .selector-group .ant-tabs-nav-container {
    margin-bottom: -14px;
} */

.selector-group .ant-tabs-ink-bar {
  height: 0px !important;
}

.selector-group .ant-tabs {
  margin: 25px 0px 0px 10px;
}

.selector-group .ant-tabs-nav .ant-tabs-tab {
  width: 70%;
  padding: 2px 12px;
  margin: 0px 0px 0px 0px !important;
}

.selector-group .ant-tabs-nav-scroll {
  border-radius: 5px;
}

.tab-pane {
  overflow: scroll;
}
.alerts-tab {
  overflow: scroll;
}
.latest-tab {
  overflow: scroll;
}

.selector-group .edit-button {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 60px;
  height: 24px;
  border-radius: 11px;
  background-color: #f0f0f0;
  cursor: pointer;
  margin: 0 4px 2px 0;
}

.selector-group .top-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.selector-group .back {
  color: #517abe;
  width: 15%;
  text-align: right;
  cursor: pointer;
  margin: 0 4px 0 0;
}

.selector-group .edit-title {
  color: black;
  font-size: 14px;
  font-weight: 500;
  margin: 0 30px 0 0;
  text-align: center;
}
