/* .ant-dropdown-link{
    text-decoration: underline;
    text-decoration-color: #f4c15c;
    font-size: 26px;
    color: black;
    font-weight: 400;
    text-underline-position:under;
}
.hub-selector a:active, .hub-selector a:hover {
    text-decoration: underline;
    text-decoration-color: #f4c15c;
    font-size: 26px;
    color: black;
    font-weight: 400;
    text-underline-position:under;
}
.ant-dropdown-menu {
    position: inherit;
    margin: 0px 9px;
} */

.ant-select {
  padding-top: 4px;
  width: auto;
}

.ant-select-selection {
  box-sizing: border-box;
  display: flex;
  background-color: transparent;
  border: 0px solid #d9d9d9;
  color: black;
  font-size: 25px;
  font-weight: 500;
}

.ant-select-arrow {
  padding-left: 3px;
  color: black;
}

.ant-select-selection-selected-value {
  margin-right: 10px;
}

.ant-select-selection__rendered {
  margin-left: 3px;
}

.ant-select-selection:focus {
  outline: none !important;
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: transparent;
  border-right-width: 0px !important;
  outline: 0;
  -webkit-box-shadow: none !important;
}

.ant-select-selection--single {
  box-sizing: border-box;
  background-color: transparent;
  border: 0px solid #d9d9d9;
}

.hub-selector .ant-select-selection:hover {
  box-shadow: none;
  background-color: transparent;
  border-color: transparent;
  border: 0px;
}

.underline {
  background-color: #f4c15c;
  height: 2px;
  width: 100%;
  margin-top: -3px;
}