.hub-form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.hub-form-container .form-top-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.hub-form-container .form-bottom-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 24px 0 0 0;
}

.hub-form-container .ant-select,
.ant-cascader-picker {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #80808047;
}

.hub-form-container .ant-form-item-label {
  line-height: 24px;
}

.hub-form-container .ant-select-selection {
  font-size: 14px;
  font-weight: 400;
}

.hub-form-container .ant-input-number {
  width: 100% !important;
}

.hub-form-container .ant-radio-wrapper {
  margin: 10px 10px 10px 10px;
}

.hub-form-container .ant-btn {
  background-color: #154479;
}

.hub-form-container .ant-btn:hover {
  background-color: #154479;
}