.item-container {
  margin: 0px 5px -13px 5px;
}

.value-slider-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px;
  width: 250px;
}

.value-slider-component {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 250px;
  padding: 5px 0px;
}

.value-slider-component .icon-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: -1%;
}

.icon-title-container .menu-icon {
  width: 25px;
  height: 25px;
}

.icon-text {


  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #1a3062;
  font-weight: 500;
  margin: 8% 0%;
}

.item-container .ant-card-body {
  padding: 4px;
}

.item-container .ant-card-bordered {

  border: none;
}

.value-slider-component .input-field-container {
  margin-top: 8px;
}

.input-field-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-right: 5%;
}

.input-field-container .ant-input-number {
  border: none;
  border-bottom: solid 1px #fec045;
  border-radius: 0px;
  font-size: 15px;
  font-weight: bold;
  color: #1a3062;
  height: 25px;
  padding: 0px !important;
}

.ant-input-number-input-wrap {
  width: 80%;
}

.input-field-container .ant-input-number-handler-wrap {
  display: none;
}

.input-field-container .ant-input-number:focus {
  box-shadow: none;
}

.ant-input-number-focused {
  box-shadow: none !important;
}

.input-field-container .right-side-element {
  margin-left: 30px;
  margin-top: 4%;
  font-size: 10px;
  font-weight: 600;
  color: #4f73b3;
  position: absolute;
}

.slider-element {
  width: 100%;
  margin-left: 2%;
  margin-top: -10px;
}

.slider-element .ant-slider {
  width: 93%;
}

.slider-element .ant-slider-rail,
.ant-slider-rail:hover {
  margin-top: 0px;
  height: 1px;
  background-color: #eaeff3;
}

.slider-element .ant-slider-track {
  height: 0px;
}

.slider-element .ant-slider-step {
  height: 5px;
  margin-top: -2px;
}

.slider-element .ant-slider-dot {
  position: absolute;
  top: 0px;
  width: 1px;
  height: 6px;
  margin-left: -4px;
  background-color: #eaeff3;
  border: none;
  border-radius: none;
  cursor: pointer;
}

.slider-element .min-max-container {
  display: flex;
  justify-content: space-between;
  height: 22px;
  font-size: 10px;
  color: #9e9e9e;
  margin: -30px 6px 0px 0px;
}