.alert-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 98%;
  height: 95px;
  background-color: #f4f7fc;
  border-radius: 5px;
  margin: 5px 0 11px 0;
}

.alert-container .alert-image {
  width: 8%;
  margin: 0 0px 30% 4%;
  height: 100%;
}

.alert-container .alert-details {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 80%;
  height: 20%;
  margin: 4% 0 0 0;
}

.alert-container .alert-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 0 0 15px;
  width: 60%;
}

.alert-container .alert-type {
  color: #547dbf;
  font-size: 11px;
  font-weight: 500;
  margin: 0 0 1px 0;
  white-space: nowrap;
}

.alert-container .alert-title {
  color: black;
  font-size: 13px;
  font-weight: 600;
  margin: -2px 0 0px 0;
}

.alert-container .alert-description {
  color: #a2a2a4;
  font-size: 87%;
  font-weight: lighter;
  padding: 0% 0 0% 0;
  overflow: auto;
  margin: 14px 0px 5px 34px;
  height: 80%;
}

.alert-time {

  font-size: 11px;
  color: #4672ba;
  font-weight: 600;
  white-space: nowrap;
  width: 0;
}

.alert-timestamp-hover {
  font-size: 12px;
  height: 25px !important;
  padding: 2px 4px !important;
  color: #4670ba !important;
  background-color: #f4f7fc !important;
}

.alert-timestamp-hover.ant-popover-placement-top>.ant-popover-content>.ant-popover-arrow {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}