.map-marker-content {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid #fec045;
  border-radius: 15px;
  width: 30px;
  height: 30px;
}

.map-marker-content .map-marker-text {
  font-size: 121%;
  font-weight: 600;
  color: #4670ba;
}

.map-marker-content .map-marker-icon {
  position: absolute;
  margin: -20px 0px 0px 14px;
}

.map-marker-content .map-marker-icon .map-marker-icon-image {
  width: 16px;
  height: 16px;
}

.leaflet-popup-content {
  margin: 0px 15px;
}

.selected-marker {
  background-color: #fec045;
  border-color: #4670ba;
}

.map-marker-tooltip {
  color: #4670ba;
  font-weight: bold;
  border-radius: 5px;
}