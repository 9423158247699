.user-adoption-chart {
  padding: 6px 0px 6px 16px;
  margin: 6px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 600px;
  height: max-content;
  background-color: white;
  border-radius: 10px;
  box-shadow: 2px 2px 12px -7px black;
}

.adoption-trend-chart {
  height: 377px;
  background-color: #e9ecf2;
  width: max-content;
  margin: 11px 0px 0px -35px;
  border-radius: 10px;
  padding: 20px;
}

/* .adoption-trend-chart .ant-spin-nested-loading > div > .ant-spin {
  position: absolute;
  top: 0;
  left: -34px;
} */

.chart-container .ant-spin-blur {
  overflow: visible !important;
}

/* .adoption-trend-chart::after {
  content: "COMING SOON...";
  z-index: 100;
  font-weight: bold;
  margin: 0px -67px -14px 283px;
  color: black;
} */

.user-adoption-container .filter-select {
  height: 55px !important;
  margin-top: 6px;
}

.trend-chart-top-container {
  align-items: center;
  width: 645px;
  margin-left: 6px;
  margin-top: -10px;
  display: flex;
  justify-content: space-between;
}

.adoption-trend-chart .filter-select {
  height: 48px !important;
  margin-top: -3px;
}

.adoption-trend-chart .ant-calendar-picker-input.ant-input {
  background-color: transparent;
}

.adoption-trend-chart .ant-calendar-picker {
  padding: 0px;
  width: 250px;
  margin-top: -3px;
  margin-left: -5px;
}

.adoption-trend-chart .filter-select-title {
  padding-left: 4%;
  margin-bottom: -5px;
}

.user-adoption-container {
  display: flex;
  flex-direction: column;
  background-color: #f7f9fb;
  overflow: scroll;
  height: max-content;
}

.user-adoption-chart .legend {
  display: none;
  margin: 20px 10px 15px 72px;
  flex-direction: row;
  justify-content: flex-start;
}

.user-adoption-chart .legend .box {
  width: 10px;
  height: 10px;
  margin-left: 5px;
  border: 1px solid #595959;
}

.user-adoption-chart .legend .box-label {
  margin-top: -3px;
  margin-left: 5px;
  font-size: 11px;
}

.charts {
  display: flex;
  flex-direction: row;
  height: 260px;
  overflow: scroll;
  padding-top: 5px;

  margin-bottom: -152px;
}

.all-branches {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.doughnut {
  display: flex;
  flex-direction: row;
  margin-left: 40px !important;
  width: 100%;
  height: 300px;
}

.user-adoption-chart .chart-container {
  margin-left: 32px;
  display: flex;
  flex-direction: row;
  justify-content: start !important;
}

.percent {
  position: absolute;
  text-align: center;
  font-size: 159%;
  font-weight: 600;
  color: #3f3f3f;
  margin-left: 91px !important;
  margin-top: 95px !important;
}

/* branch details shown */
.scroll .branch-details {
  unicode-bidi: bidi-override;
  direction: rtl;
  overflow: scroll;
  overflow-x: hidden !important;
}

.branch-details {
  padding: 0px 12px 0px 15px !important;
  font-weight: 700;
  color: #595959;
  display: flex;
  flex-direction: column !important;
  background-color: #ffffff !important;
  margin-left: 15px;
  margin-top: -35px !important;
  text-align: center;
  width: 100%;
  overflow: scroll;
  direction: rtl;
  height: 335px;
}

.branch-details .ant-btn {
  padding: 0px;

  background-color: #030a30;
  color: white;
  width: 28px;
  height: 28px;
  font-size: 23px;
  margin-right: 6px;
}

.branch-details .ant-btn span {
  margin-left: -1px;
}

.branch-name,
.branch-percent {
  vertical-align: middle;
  font-weight: 700;
  color: #595959;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  background-color: #ffffff00 !important;
  margin-top: 5px;
  width: max-content !important;
  height: max-content !important;
}

.branch-percent {
  width: 43px !important;
}

.total-single-detail-container {
  -youbkit-touch-callout: none;
  /* iOS Safari */
  -youbkit-user-select: none;
  /* Chrome 6.0+, Safari 3.1+, Edge & Opera 15+ */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE 10+ and Edge */
  user-select: none;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 0px 20px 0px 10px;
  direction: ltr;
  border-radius: 30px 0px 0px 30px;
  margin-top: -1px;
  margin-right: -12px;
  justify-content: space-between;
}

.total-single-detail-container.selected-branch {
  background-color: #eaf0fa;
}

.doughnut-in-name {
  width: 25px;
  height: 25px;
}

/* for filters */
.user-adoption-container .summary .ant-select-selection {
  background-color: white;
  color: #4672ba;
  padding-left: 8px;
  font-size: 14px;
  width: 150px;
  border: 0px solid #d9d9d9;
}

.filter-container {
  margin-bottom: 25px;
  background: transparent;
}

.user-adoption-container .summary .summary-filters {
  padding: 11px 13px 0px 20px;
  margin-bottom: -18px;
}

.user-adoption-container .summary .ant-select-selection:hover,
.user-adoption-container .summary .ant-select-selection:focus {
  border: none !important;
}

.user-adoption-container .ant-calendar-picker-input {
  outline: none;
  border: none;
  margin-left: -6px;
}

.ant-select-arrow .ant-select-arrow-icon svg {
  color: #acacac;
}

.filter-select {
  border-radius: 8px;
  padding: 5px 2px 0px 2px;
  background-color: white;
  border: 1px solid rgb(221, 221, 221);
  box-shadow: 2px 2px 6px -6px black;
}

.filter-select-title {
  color: rgb(172, 172, 172);
  padding-left: 7%;
  margin-bottom: -5px;
  font-size: 11px;
}

/* .total-details-container {
  width: max-content;
  margin-left: -42px;
} */


.date-single .ant-calendar-picker {
  width: 130px;
}

.date-single .ant-calendar-picker-input.ant-input {
  font-weight: 600 !important;
  padding: 4px 12px !important;
}

/* .date-sigle .ant-calendar-picker-input.ant-input {
  padding: 4px 16px !important;
} */
.anticon.anticon-close-circle.ant-calendar-picker-clear {
  display: none;
}

.single-select .ant-calendar-picker-icon {
  display: inline-block;
  color: rgba(0, 0, 0, 0.699);
  font-size: 14px;
  line-height: 1;
  margin-right: 114px;
}

.single-select {
  padding-left: 10px;
  margin-top: -23px;
}

.single-select .date-single .ant-calendar-picker-input.ant-input {
  color: #262626;
}