.intra-day-report .summary .summary-filters {
  background-color: #f7f9fb;
  padding: 13px 13px 0px 0px;
}

.intra-day-report .ant-select-selection--single .ant-select-selection__rendered {
  overflow: hidden;
}

.intra-day-report .summary .ant-table-thead>tr>th {
  padding: 6px 6px;
}

.filter-select .ant-select-show-arrow .ant-select-selection--multiple .ant-select-selection__rendered {
  margin-top: -2px;
  overflow: hidden !important;
  height: 35px;
}

.anticon.anticon-check.ant-select-selected-icon {
  display: inline-block;
  border: 1px solid #1890ff;
  color: #1890ff;
}

.mandi-index-report .ant-table-thead>tr>th .ant-table-header-column .ant-table-column-sorters {
  display: flex;
  justify-content: space-between;
  margin-left: 6px;
}

.intra-day-report .summary .ant-table-thead>tr:first-child>th:nth-child(2),
.intra-day-report .summary .ant-table-thead>tr:first-child>th:nth-child(3) {
  text-align: center !important;
}

.intra-day-report .filter-container {
  margin-bottom: 0px;
}

.intra-day-report .summary .ant-table-thead>tr:first-child>th {
  padding: 6px 16px;
}

.intra-day-report .filter-select {
  height: 55px;
  border-radius: 8px;
  padding: 3px 2px 0px 0px;
  background-color: white;
  border: 1px solid rgb(221, 221, 221);
  box-shadow: 2px 2px 6px -6px black;
}

.top-btn-container {
  display: flex;
  height: 61px;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 7px;
  margin-left: 10px;
  margin-bottom: -1px;
  margin-right: 10px;
  background-color: #253857;
  border-radius: 5px 5px 0px 0px;
}

.container-btn {
  cursor: pointer;
  width: max-content;
  padding: 20px 9px;
  height: 61px;
  background-color: #253857;
  color: #6d82a4;
  border-radius: 5px;
}

.container-btn-active {
  cursor: default;
  height: 120%;
  border-radius: 5px 5px 0px 0px;
  background-color: #4672ba;
  color: white;
  padding-top: 24px;
}

.intra-day-report .ant-calendar-picker {
  padding: 4px;
  width: 215px;
}

.date-simple .ant-calendar-picker {
  width: 130px;
}

.date-simple .ant-calendar-picker-input.ant-input {
  font-weight: 600 !important;
}

.intra-day-report .summary-filters {
  justify-content: space-between;
}

.efficiency-table-container .ant-table-thead>tr:first-child>th:nth-child(2) {
  text-align: center;
}

/* .efficiency-table-container
    .ant-table-thead
    > tr:nth-child(2)
    > th:nth-child(10)
    .ant-table-header-column {
    color: #bad2f8 !important;
    font-size: 12px !important;
    margin-top: -0px;
  } */
.mandi-trend-graph-container {
  background-color: #f7f9fb;
}

.multiple-select {
  margin-top: -15px;
  width: 180px;
}

.multiple-select .ant-select {
  margin-top: 0px;
  background-color: transparent;
}

.multiple-select .ant-select-selection--multiple .ant-select-selection__placeholder {
  margin-left: 4px;
  margin-top: -18px;
}

.multiple-select .ant-select-selection--multiple .ant-select-arrow {
  top: 15px;
}

.filter-select .multiple-select .ant-select-selection {
  height: 30px;
  width: 180px;
}

.filter-select .multiple-select .ant-select-selection--multiple .ant-select-selection__rendered>ul>li {
  height: 24px;
  margin-top: 0px;
  margin-bottom: -7px;
  padding: 0px;
  border: none;
  line-height: 22px;
  background: white;
}

.filter-select .multiple-select .ant-select-selection--multiple .ant-select-selection__choice__remove {
  display: none;
}

.ant-select-selection__choice::after {
  content: ",";
  vertical-align: top;
}


.multiple-select .ant-select-search--inline .ant-select-search__field__wrap {
  width: 180px;
}

.filter-select .multiple-select .ant-select-selection__rendered ul li:nth-last-child(2)::after {
  content: "" !important;
}

/* .filter-select .multiple-select .ant-select-search__field {
  pointer-events: none !important;
} */

.filter-select .ant-calendar-range-picker-input {
  margin-left: 3px !important;
}

.filter-select .ant-calendar-picker-input.ant-input {
  padding: 4px 0px;
}

/* custom row change for table header */
.mandi-index-report .ant-table-thead>tr:first-child>th:nth-child(1),
.mandi-index-report .ant-table-thead>tr:nth-child(2)>th {
  border-bottom: none;
}

.mandi-index-report .ant-table-thead>tr:nth-child(3)>th,
.mandi-index-report .ant-table-thead>tr:nth-child(3)>th .ant-table-header-column .ant-table-column-sorters::before {
  background-color: #3c5a93 !important;
}

.mandi-index-report .ant-table-thead>tr:nth-child(3)>th {
  padding: 6px 10px !important;
}

.mandi-index-report .ant-table-thead>tr:nth-child(2)>th {
  padding: 6px 16px !important;
}

.mandi-index-report .ant-table-thead>tr>th .ant-table-column-sorter {
  vertical-align: inherit;
  margin-top: -30px;
}

.mandi-index-report .ant-table-thead>tr:nth-child(3)>th:not(:last-child) {
  border-right: 1px solid #8ba4d2;
}

/* DEFINE INDEX */
.add-index-btn {
  margin: 27px -83px 0px 0px;
}

.intra-day-report .add-index-btn .ant-btn {
  color: white;
  background-color: #154479;
}

.intra-day-report .define-index .ant-table-thead>tr:first-child>th {
  padding: 9px 16px;
}

.ant-modal.add-index-modal .ant-modal-header {
  background: #154479;
  border-radius: 10px 10px 0 0;
}

.ant-modal.add-index-modal .ant-modal-header .ant-modal-title {
  color: #ffffffd9;
}

.ant-modal.add-index-modal .ant-modal-content {
  border-radius: 10px 10px;
}

.ant-modal.add-index-modal .ant-modal-close {
  color: rgba(255, 255, 255, 0.84);
}

.ant-modal.add-index-modal .ant-modal-body {
  background: white;
  font-size: 12px;
  padding: 8px;
  font-weight: 600;
  color: black;
}

.ant-modal.add-index-modal .ant-modal-footer {
  background-color: white;
  border-radius: 0px 0px 10px 10px;
}

.ant-modal.add-index-modal .ant-modal-footer>div>button:first-child {
  background-color: #cbcbcb !important;
}

.ant-modal.add-index-modal .ant-select.ant-select-enabled {
  display: flex !important;
}

.ant-modal.add-index-modal .ant-select.ant-select-enabled .ant-select-selection {
  font-size: 14px;
  color: #282727;
  font-weight: 300;
  width: 100%;
  border: 1px solid #d9d9d9;
}

.ant-modal.add-index-modal .ant-select-disabled .ant-select-selection {
  font-size: 14px;
  color: #282727;
  font-weight: 300;
  width: 280px;
  border: 1px solid #d9d9d9;
}

.ant-modal.add-index-modal .ant-select-disabled .ant-select-selection:hover {
  background-color: #f5f5f5;
  border: 1px solid #d9d9d9;
}

.ant-modal.add-index-modal .ant-select-selection:hover {
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
}

.ant-modal.add-index-modal .ant-select-selection:active,
.ant-modal.add-index-modal .ant-select-selection:focus {
  border-right: 1px solid #d9d9d9 !important;
}

.ant-modal.add-index-modal .ant-select-selection--single .ant-select-selection__rendered {
  padding-left: 10px;
  width: 87%;
}

.ant-modal.add-index-modal .ant-select-selection--multiple .ant-select-selection__rendered {
  height: 62px;
}

.ant-modal.add-index-modal .ant-select-selection--multiple .ant-select-selection__placeholder {
  margin-top: -26px;
  margin-left: 6px;
}

.ant-modal.add-index-modal .ant-radio-group {
  display: flex;
  width: 103%;
  justify-content: space-between;
}

.index-form-container {
  display: flex;
  flex-direction: column;
}

.top-container {
  display: flex;

  width: 100%;
}

.name-branch-container {
  width: 50%;
  padding: 0 20px;
  line-height: 28px;
}

.type-variety-container {
  width: 50%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  line-height: 27px;
}

.type-variety-container .ant-radio-wrapper {
  padding: 5px 5px 7px 5px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  color: #9f9f9f;
  direction: rtl;
}

.type-variety-container .ant-radio-wrapper.ant-radio-wrapper-checked {
  border-color: #154479;
  background: #f2f7fc;
  color: #0a0a0b;
}

.type-variety-container .ant-radio-checked .ant-radio-inner {
  border-color: #154479;
}

.type-variety-container .ant-radio-checked .ant-radio-inner::after {
  background-color: #154479;
}

.bottom-index-container {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.total-container {
  width: 50%;

}

.header-list {
  background-color: #f8f8f8;
  height: 30px;
  border: 1px solid #e6e6e6;
  padding: 4px 19px 0 19px;
}

.list-container {
  display: flex;
  flex-direction: column;
  height: 235px;
  overflow: scroll;
  padding-top: 10px;
}

.single-item-container {
  margin: 2px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 30px;
  width: 100%;
}

.item-name {
  padding-top: 3px;
}

.index-arrow {
  -youbkit-touch-callout: none;
  /* iOS Safari */
  -youbkit-user-select: none;
  /* Chrome 6.0+, Safari 3.1+, Edge & Opera 15+ */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE 10+ and Edge */
  user-select: none;
  width: 27px;
  font-weight: 100;
  border-radius: 7px;
  text-align: center;
  height: 27px;
  background: #4672ba;
  color: white;
  padding-top: 1px;
  font-size: 16px;
  cursor: pointer;
}

.index-cross {
  -youbkit-touch-callout: none;
  /* iOS Safari */
  -youbkit-user-select: none;
  /* Chrome 6.0+, Safari 3.1+, Edge & Opera 15+ */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE 10+ and Edge */
  user-select: none;
  width: 27px;
  border-radius: 7px;
  text-align: center;
  height: 27px;
  font-weight: 100;
  background: black;
  color: white;
  padding-top: 1px;
  font-size: 16px;
  cursor: pointer;
}

.index-search-container {
  display: flex;
  justify-content: space-around;
  padding: 5px 34px 5px 19px;
}

.index-search-container .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  border-radius: 16px;
  height: 27px;
}

/* INDEX DISPLAY PAGE */

.index-action-container {
  background-color: #f0f0f0;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 55px;
  padding: 2px 2px 2px 3px;
  cursor: pointer;
}

/* NEW DESIGN CHANGES FOR REPORT */

.new-bar {
  border-radius: 11px;
  height: 23px;
  display: flex;
  padding: 2px 8px;
  font-size: 11px;
  justify-content: space-between;
  width: 78px;
}