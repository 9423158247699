.monthly-price-container {
  display: flex;
  flex-direction: column;
  background: #f7f9fb;
}

.monthly-price-container .top-container {
  display: flex;
  flex-direction: row;
  width: 52%;
  justify-content: space-between;
  margin: 0 0 5px 0;
}

.top-container .schedule-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #154479;
  color: white;
  border-radius: 5px;
  margin: 10px 10px;
  width: 127px;
  height: 55px;
  cursor: pointer;
  text-align: center;
}
.top-container .schedule-button-disabled {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #8a8a8a;
  color: white;
  border-radius: 5px;
  margin: 10px 10px;
  width: 127px;
  height: 55px;
  pointer-events: none;
  text-align: center;
}

.top-container .edit-button {
  width: 85px;
  height: 35px;
  background: #154479;
  color: white;
  border-radius: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 35px 30px 0 0;
  font-weight: 400;
}
.top-container .cancel-button {
  width: 85px;
  height: 35px;
  background: #f05e5e;
  color: white;
  border-radius: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 35px 30px 0 0;
  font-weight: 400;
}

.temporal-select {
  width: max-content;
  height: 55px;
  border-radius: 8px;
  padding: 5px 2px 0px 2px;
  background-color: white;
  border: 1px solid rgb(221, 221, 221);
  box-shadow: 2px 2px 6px -6px black;
  margin: 10px 0 0 0;
}

.temporal-select-title {
  color: rgb(172, 172, 172);
  padding: 0 7px;
  margin-bottom: -5px;
  font-size: 11px;
  white-space: nowrap;
}

.temporal-select .ant-select-selection-selected-value {
  margin-right: 10px;
  font-size: 14px;
}

.temporal-select .ant-select-dropdown {
  width: 10px !important;
}

.monthly-price-container .table-list-container {
  overflow: scroll;
  margin: 0 0 0 2px;
}

.temporal-modal .ant-modal-header {
  background: #f05e5e;
}

.temporal-modal .ant-modal-footer .ant-btn-primary {
  background-color: #f05e5e !important;
}
