.login-container {
  margin-top: 0px;
  display: block;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin: auto auto;
  border: 0px #f0f2f5;
  background-color: transparent;
  border-style: solid;
  border-radius: 15px;
  width: 300px;
  height: 400px;
  overflow: hidden;
  z-index: 10;
}

.background-tile {
  position: absolute;
  background-size: 100px;
  width: 100%;
  height: 96.5%;
}

.login-main-container {
  background-color: #f8f8f8;
  background-repeat: repeat-x;
  background-repeat: repeat-y;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.login-main-container .ant-input {
  background-color: #f0f2f5;
  border: none;
}

.logo-container {
  display: flex;
  flex-direction: row;
  height: 100px;
  background-color: #030b30;
  border-radius: 0 50px 0px 50px;
  width: 300px;
  margin-bottom: 3px;
}

.login-logo {
  margin-left: -1px;
  border-radius: 50px;
  overflow: hidden;
  width: 100px;
  margin: 17px auto 0 auto;
}

.logo-container .title-container {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
  padding: 0px 0px 0px 13px;
  border-radius: 0 50px 0 50px;
  background-color: #030b30;
}

.logo-container .title-container h1 {
  color: #f0f0f0;
  font-weight: 400;
  margin-top: 3px;
}

.logo-container .title-container h3 {
  color: #f0f0f0;
  font-weight: 400;
  margin-top: -25px;
}

.login-div {
  margin-top: 0;
  text-align: center;
  height: 12%;
  background-color: #030b30;
  color: rgb(224, 224, 224);
  font-size: 22px;
  display: flex;
  justify-content: center;
}

.login-div p {
  margin-top: 7px;
}

.textbox {
  width: 100%;
  margin-top: 0px;
  color: #030b30;
  font-weight: 600;
}

.inputs {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 6px;
  height: 33%;
  padding: 20px 0px;

  .ant-form-item {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 0px;
  }
}

.dots {
  margin-top: -7px;
  color: #e9e9e9;
}

.line {
  margin-top: -27px;
  height: 2px;
  width: 70%;
  background-color: #f4c15c;
}

.login-main-container .ant-input:focus {
  box-shadow: none;
}

.login-main-container .ant-input {
  background-color: #ffffff !important;
  width: 258px;
}

.login-main-container .button {
  /* margin-top: 15px; */
  background-color: #f1bf1e;
  color: #030b30;
  font-weight: 600;
  border: none;
  width: 100%;
  height: 41px;
  border-radius: 6px;
}

.login-main-container .button:focus {
  outline: none;
}

.login-container .button:hover {
  color: white;
  background-color: #f1bf1e;
}
