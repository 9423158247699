.new-variety-select {
  display: flex;
  width: 55%;
  height: 18px;
  margin: -10px 0 10px 0;
}

.new-variety-select.ant-select-disabled .ant-select-selection {
  background: none;
  cursor: not-allowed;
}

.new-variety-select .ant-select-selection-selected-value {
  float: left;
  color: #1a3062;
  font-size: 12px;
  font-weight: 800;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.select-text.ant-select-dropdown-menu-item {
  position: relative;
  display: block;
  padding: 5px 12px;
  overflow: hidden;
  color: #1a3062;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}
