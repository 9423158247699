.model-run-visualization-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgb(247 249 251);
  align-items: center;
}

.model-run-visualization-container .graph-radio-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 90%;
  background: white;
  height: 50px;
  margin: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.model-run-visualization-container
  .ant-select-selection--multiple
  .ant-select-selection__rendered {
  height: auto;
  margin-bottom: -3px;
  margin-left: 5px;
  font-size: small;
}

.model-run-visualization-container .ant-select {
  max-width: 430px;
  padding: 4px 0 9px 0;
}
