.weekly-override-card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #80808061;
  border-radius: 5px;
  padding: 5px;
  background: white;
  margin: 0 15px 0 5px;
}

.weekly-override-card-container .title {
  color: #4672ba;
  font-weight: 700;
  text-align: center;
}
.weekly-override-card-container .bottom-line {
  border-bottom: 2px solid #4672bac4;
}
.weekly-override-card-container .display-info {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin: 5px 5px;
  border-bottom: 1px solid #5d4f4f7d;
  align-self: center;
  width: -webkit-fill-available;
  color: black;
}

.weekly-override-card-container .ant-input-number-input {
  text-align: center;
}

.weekly-override-card-container .ant-input-number-input-wrap {
  width: 100%;
}

.weekly-override-card-container .modified-input {
  border: 1px solid #122144;
}
