.quality-container {
  margin: 0px 5px -13px 5px;
}

.quality-container .value-slider-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px;
  width: 250px;
  margin-top: -20px;
}

.quality-container .value-slider-component {
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
  /* padding: 5px; */
  width: 250px;
}
.quality-container
  .value-slider-component
  .ant-input-number-input:placeholder-shown {
  text-overflow: initial;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 13px;
  font-weight: 500;
}

.quality-container .value-slider-component .icon-title-container {
  height: 39px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-left: -1%;
}

.quality-container .icon-title-container .menu-icon {
  width: 25px;
  height: 25px;
}

.quality-container .icon-text {
  white-space: nowrap;
  color: #1a3062;
  font-weight: 500;
  margin: 13% 0%;
  /* margin-top: 14%; */
}

.quality-container .ant-card-body {
  padding: 4px;
}

.quality-container .ant-card-bordered {
  /* border-radius: 5px; */
  border: none;
}

.quality-container .value-slider-component .input-field-container {
  margin-top: -1px;
  width: 100%;
}

.quality-container .input-field-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-right: 5%;
}

.quality-container .input-field-container .ant-input-number {
  border: none;
  border-bottom: solid 1px #fec045;
  background: transparent;
  border-radius: 0px;
  font-size: 15px;
  font-weight: bold;
  color: #1a3062;
  height: 25px;
  padding: 0px !important;
}

.quality-container .input-field-container .ant-input-number-handler-wrap {
  display: none;
}

.quality-container .quality-text {
  font-size: 15px;
  font-weight: bold;
  color: #1a3062;
  margin: 0px 0px 0px 0px;
}

.input-field-container .ant-input-number:focus {
  box-shadow: none;
}

.ant-input-number-focused {
  box-shadow: none !important;
}

.input-field-container .right-side-element {
  margin-left: 30px;
  margin-top: 4%;
  font-size: 10px;
  font-weight: 600;
  color: #4f73b3;
  position: absolute;
}

.slider-element {
  padding: 0 4%;
  width: 100%;
  margin-left: 2%;
  margin-top: -10px;
}

.slider-element .ant-slider {
  width: 93%;
}

.slider-element .ant-slider-rail,
.ant-slider-rail:hover {
  margin-top: 0px;
  height: 1px;
  background-color: #eaeff3;
}

.slider-element .ant-slider-track {
  height: 0px;
}

.slider-element .ant-slider-step {
  height: 5px;
  margin-top: -2px;
}

.slider-element .ant-slider-dot {
  position: absolute;
  top: 0px;
  width: 1px;
  height: 6px;
  margin-left: -4px;
  background-color: #eaeff3;
  border: none;
  border-radius: none;
  cursor: pointer;
}

.slider-element .min-max-container {
  display: flex;
  justify-content: space-between;
  height: 22px;
  font-size: 10px;
  color: #9e9e9e;
  margin: -30px 6px 0px 0px;
}

.quality-container .ant-slider-mark-text {
  font-size: 10px;
  margin-top: -8px;
}

.quality-container .text-line-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-left: 41px;
  /* justify-content: space-between */
}

.quality-container .text-line-container .ant-input-number {
  width: 90%;
}
.quality-container .text-line-container .ant-input-number-input-wrap {
  width: 82%;
}

.quality-container
  .text-line-container
  .ant-input-number
  .ant-input-number-handler-wrap {
  width: 20px;
}
.quality-container
  .text-line-container
  .ant-input-number-input:placeholder-shown {
  margin-left: -4px;
  width: 113%;
}

.quality-container .text-line-container .input-field-container {
  width: 72px;
}

.quality-container .line {
  width: 100%;
  height: 1px;
  background-color: #fec045;
}
.quality-container .conditional-custom-dropdown {
  margin-left: 78px;
  margin-top: 4px;
}
.quality-container .ant-select-selection {
  width: 90px;
  box-sizing: border-box;
  display: flex;
  background-color: transparent;
  border: none;
  color: #1a3062;
  font-size: 14px;
  font-weight: 700;
  /* border-top-width: 1.02px; */
  /* border-radius: 4px; */
  /* outline: none;*/
}
.quality-container .ant-select-selection:hover {
  background-color: transparent;
}
.quality-container .underline {
  background-color: #f4c15c;
  height: 1px;
  margin-top: -7px;
}
.quality-container .ant-select-arrow {
  padding-left: 3px;
  color: #1a3062;
}

.quality-input-container span {
  font-weight: 500;
  font-size: 9px;
  padding-left: 5px;
  color: #4f73b3 !important;
  /* opacity: 0.7; */
}

.quality-input-container {
  line-height: 6px;
}

.value-slider-component .input-field-container {
  margin-top: 8px;
  width: 81px;
}
