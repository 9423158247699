.arrival-page-container .map-container .input-container .region-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.arrival-page-container .map-container .input-container .hub-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.input-container .ant-select-selection {
  background-color: white;
  margin-top: 5px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 300;
  width: 156px;
  padding-left: 5px;
  color: rgb(43, 43, 43);
}

.input-container .ant-select-selection:hover {
  border: none;
  background-color: white;
}