.edit-wishlist-item-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f4f7fc;
  margin: 6px 0 6px 0;
  border-radius: 5px;
}

.edit-wishlist-item-container .variety-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.edit-wishlist-item-container .variety-items .variety-name {
  color: black;
  font-weight: 500;
  margin: 4px 0 4px 8px;
}

.edit-wishlist-item-container .variety-items .close-variety-image {
  width: 19px;
  margin: -9px 0px 0 0;
  cursor: pointer;
}

.edit-wishlist-item-container .mandi-list-items {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.edit-wishlist-item-container .mandi-list-items .mandi-item-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.edit-wishlist-item-container .mandi-item-container .mandi-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.edit-wishlist-item-container .mandi-item .remove-mandi-image {
  width: 18px;
  margin: 0 14px 0 0px;
  cursor: pointer;
}
.edit-wishlist-item-container .mandi-item .mandi-name {
  color: #8e93af;
  margin: 0 0 0 8px;
  font-weight: 400;
}
.edit-wishlist-item-container .mandi-item-line {
  background-color: #8e93af;
  width: 195px;
  height: 1px;
  margin: 4px 0 5px 8px;
  opacity: 0.5;
}

.edit-wishlist-item-container .mandi-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.edit-wishlist-item-container .add-mandi-button {
  display: flex;
  margin: 5px 0 4px 0;
  flex-direction: row;
  cursor: pointer;
  justify-content: flex-start;
}

.edit-wishlist-item-container .add-mandi-button .add-image {
  width: 17px;
  margin: 0 4px 0 6px;
}

.edit-wishlist-item-container .add-mandi-button .add-mandi-text {
  color: #4670ba;
  font-size: 14px;
}

.edit-wishlist-item-container .mandi-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 14px 0 0;
  width: 50px;
  background: transparent;
}

.edit-wishlist-item-container
  .mandi-item
  .mandi-select
  .ant-select-selection-selected-value {
  margin-right: 10px;
  font-size: 12px;
}
.edit-wishlist-item-container .mandi-item .mandi-select {
  margin: -3px 0 0px 8px;
}

.edit-wishlist-item-container
  .mandi-item
  .mandi-select
  .ant-select-search--inline
  .ant-select-search__field__wrap {
  width: 100%;
  height: 100%;
  font-size: 14px;
}
