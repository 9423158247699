.input-field-container {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-around !important;
  margin-right: 0% !important;
}

.input-field-container .ant-input-number {
  border: none;
  border-bottom: solid 1px #fec045;
  border-radius: 0px;
  font-size: 15px;
  font-weight: bold;
  color: #1a3062;
  height: 25px;
  padding: 0px !important;
}

.input-field-container .ant-input-number-handler-wrap {
  overflow: hidden;
}

.input-field-container .ant-input-number:focus {
  box-shadow: none;
}

.ant-input-number-focused {
  box-shadow: none !important;
}

.input-field-container .right-side-element {
  margin-left: 30px;
  margin-top: 4%;
  font-size: 10px;
  font-weight: 600;
  color: #4f73b3;
  position: absolute;
}
.ant-input-number-input {
}
