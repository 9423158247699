.temporal-table-expanded.extra {
  margin: 10px 10px 20px 10px !important;
}

.temporal-table-expanded .model-data-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
}

.model-data-card .title {
  margin: 2px;
  font-size: 11px;
  font-weight: 400;
  text-align: center;
  color: #262626;
}
