.efficiency-report .summary .summary-filters {
  background-color: #f7f9fb;
  padding: 14px 13px 0px 0px;
}

.efficiency-report .summary .ant-table-thead>tr>th {
  padding: 6px 6px;
}

.efficiency-table-container .ant-table-thead>tr:first-child>th:nth-child(2) .ant-table-header-column,
.efficiency-table-container .ant-table-thead>tr:first-child>th:nth-child(3) .ant-table-header-column {
  vertical-align: top !important;
  text-align: center;
}

.efficiency-table-container .ant-table-tbody>tr>td {
  padding: 3px 8px;
}

.deviation-table-container .ant-table-tbody>tr>td:nth-child(8) {
  background-color: #ffffff;
}

.efficiency-table-container .ant-table-tbody>tr>td:nth-child(8) {
  background-color: #ffffff;
}

.efficiency-table-container .ant-table-tbody>tr>td:nth-child(6) {
  padding: 0px 0px !important;
  margin: 0px;
  vertical-align: top;
}

/* .efficiency-table-container
  .ant-table-tbody
  > tr
  > td:nth-child(8)
  .nt-table-row-cell-break-word {
  padding: 0px !important;
} */

.efficiency-report .summary .ant-table-thead>tr:nth-child(2)>th:nth-child(10) {
  vertical-align: top;
  text-align: left !important;
}

.efficiency-report .filter-container {
  margin-bottom: 0px;
}

.efficiency-report .summary .ant-table-thead>tr:nth-child(2)>th .ant-table-header-column {
  color: #ffffff !important;
  font-size: 12px !important;
  font-weight: normal !important;
}

.efficiency-report .summary .ant-table-thead>tr:first-child>th {
  padding: 6px 16px;
}

.efficiency-report .filter-select {
  height: 55px;
  border-radius: 8px;
  padding: 3px 2px 0px 0px;
  background-color: white;
  border: 1px solid rgb(221, 221, 221);
  box-shadow: 2px 2px 6px -6px black;
}

.top-btn-container {
  display: flex;
  height: 61px;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 7px;
  margin-left: 10px;
  margin-bottom: -1px;
  margin-right: 10px;
  background-color: #253857;
  border-radius: 5px 5px 0px 0px;
}

.container-btn {
  cursor: pointer;
  width: max-content;
  padding: 20px 9px;
  height: 61px;
  background-color: #253857;
  color: #6d82a4;
  border-radius: 5px;
}

.container-btn-active {
  cursor: default;
  pointer-events: none;
  height: 120%;
  border-radius: 5px 5px 0px 0px;
  background-color: #4672ba;
  color: white;
  padding-top: 24px;
}

.deviation-table-container .ant-table-tbody>tr>td {
  padding: 3px 8px;
}

.efficiency-report .ant-calendar-picker {
  padding: 4px;
  width: 215px;
}

.date-simple .ant-calendar-picker {
  width: 130px;
}

.date-simple .ant-calendar-picker-input.ant-input {
  font-weight: 600 !important;
}

.efficiency-report .summary-filters {
  justify-content: center;
}

.efficiency-table-container .ant-table-thead>tr:first-child>th:nth-child(2) {
  text-align: center;
}

/* .efficiency-table-container
  .ant-table-thead
  > tr:nth-child(2)
  > th:nth-child(10)
  .ant-table-header-column {
  color: #bad2f8 !important;
  font-size: 12px !important;
  margin-top: -0px;
} */
.deviation-table-container .ant-table-thead>tr:first-child>th:nth-child(3) .ant-table-header-column,
.deviation-table-container .ant-table-thead>tr:first-child>th:nth-child(4) .ant-table-header-column,
.deviation-table-container .ant-table-thead>tr:first-child>th:nth-child(5) .ant-table-header-column,
.deviation-table-container .ant-table-thead>tr:first-child>th:nth-child(6) .ant-table-header-column {
  text-align: center;
}

.summary .table-container .efficiency-table-container .ant-table-tbody>tr>td:nth-child(11),
.deviation-table-container .ant-table-tbody>tr>td:nth-child(10),
.deviation-table-container .ant-table-tbody>tr>td:nth-child(11),
.deviation-table-container .ant-table-tbody>tr>td:nth-child(9) {
  padding: 0px !important;
}

.percent-col {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 25px;
  padding: 3px 8px;
}

.red {
  background-color: #fdebec !important;
  color: #f26061 !important;
}

.green {
  background-color: #ebfaeb !important;
  color: #5bc95a !important;
}

.arrow {
  margin-top: 5px;
  width: 6px;
  height: 6px;
}

.down {
  transform: rotate(180deg);
}

/* .ant-table-row-indent.indent-level-1 {
  padding-left: 1px !important;
  margin-left: 8px !important;
  background: black !important;
  width: 1px !important;
} */
/* .ant-table-row-indent.indent-level-1::after {
  content: "|-";
} */

.ant-table-row.ant-table-row-level-1 .ant-table-row-cell-break-word {
  background-color: #f5f5f5;
}

.ant-table-row.ant-table-row-level-1 .ant-table-row-cell-break-word:nth-child(8) {
  background-color: #f5f5f5 !important;
}

.ant-table-row.ant-table-row-level-1 .ant-table-row-cell-break-word:nth-child(6) .percent-col {
  background-color: #f5f5f5 !important;
}

.ant-table-row-expand-icon.ant-table-row-collapsed {
  border-radius: 6px;
  border-color: #262626;
  background: #ededed;
  color: #262626;
}

.ant-table-row-expand-icon.ant-table-row-expanded {
  border-radius: 6px;
  border: none;
  background: #4672ba;
  color: #ffffff;
}

.ant-table-row-expanded::after {
  content: "-";
  line-height: 14px;
}

.ant-table-row.ant-table-row-level-1 .transparent .percent-col {
  background-color: #f5f5f5;
}

.date-simple .ant-calendar-picker-input.ant-input {
  padding: 4px 6px !important;
}

/* Style for custom rows deviation */
.deviation-table-container .ant-table-thead>tr:nth-child(1)>th:nth-child(1),
.deviation-table-container .ant-table-thead>tr:nth-child(1)>th:nth-child(2),
.deviation-table-container .ant-table-thead>tr:nth-child(2)>th {
  border-bottom: none;
}

.deviation-table-container .ant-table-thead>tr:nth-child(3)>th {
  background-color: #3c5a93;
}

.deviation-table-container .ant-table-thead>tr:nth-child(3)>th:not(:last-child) {
  border-right: 1px solid #8ba4d2;
}

/* Style for custom rows efficiency */
.efficiency-table-container .ant-table-thead>tr:nth-child(1)>th:nth-child(1),
.efficiency-table-container .ant-table-thead>tr:nth-child(2)>th {
  border-bottom: none;
}

.efficiency-table-container .ant-table-thead>tr:nth-child(3)>th {
  background-color: #3c5a93;
}

.efficiency-table-container .ant-table-thead>tr:nth-child(3)>th:not(:last-child) {
  border-right: 1px solid #8ba4d2;
}