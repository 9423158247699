.download-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: fit-content;
  bottom: 7%;
  right: 1%;
  padding: 3px 0;
  margin: 220px 5px 0px -40px;
  text-align: center;
  position: fixed;
  z-index: 1000;
}

.time-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 3px 0;
  margin: 7px 0 6px 11px;
  text-align: center;
}

.download-button-container .ant-btn>.anticon {
  line-height: 1;
  margin-top: 5px;
  margin-right: 5px;
  margin-left: -4px;
}

.time-container .top-left-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 50%;
  margin: 0px 0px 0px 60px;
}

.time-container .top-right-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 50%;
  margin: 8px 60px 0px 0px;
}

.download-button-container .ant-btn {
  background-color: #154478;
}

.download-button-container .ant-btn-round.ant-btn-lg {
  height: 40px;
  font-size: 13px;
}

.download-report-container .ant-table {
  color: #1a3062;
  font-size: 12px;
  font-weight: 700;
}

.ant-spin {
  color: #004eff;
}

.table-container.audit-table {
  height: 100% !important;

  overflow: scroll;
  background-color: #f7f9fb;
}

.table-container.audit-table .ant-table-scroll {
  width: 100%;
}

.ant-spin-dot-item {
  background-color: #004eff;
}

.table-container .ant-table-filter-dropdown .ant-dropdown-menu-without-submenu {
  max-height: 244px;
  overflow-x: hidden;
}

.table-container .ant-table-thead>tr>th .anticon-filter,
.ant-table-thead>tr>th .ant-table-filter-icon {
  height: max-content;
  margin-top: 23px;
  background-color: #558adf;
}

.table-container .ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
  background: #4672ba;
}

.table-container .ant-table-thead>tr>th .ant-table-header-column .ant-table-column-sorters::before {
  background: #4672ba !important;
}

.table-container .ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-filters .anticon-filter.ant-table-filter-open,
.ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-filters .ant-table-filter-icon.ant-table-filter-open {
  background: #4672ba;
}

.table-container .ant-table-thead>tr>th .ant-table-column-sorter {

  vertical-align: inherit;
}

.ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .anticon-filter,
.ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .ant-table-filter-icon {
  background: #4672ba;
}

.download-report-container .audit-table .ant-table-thead>tr>th {
  vertical-align: top;
  text-align: left;
}

.download-report-container .ant-table-tbody>tr>td {
  padding: 8px 16px;
  overflow-wrap: break-word;
  text-align: start;
  color: rgb(26, 26, 26);
  font-weight: 500;
  font-size: 12px;
}

/* 
.download-report-container .column-header-container {
  text-align: center;
} */

.top-run-date {
  font-size: 21px;
  font-weight: 500;
  color: rgb(26, 26, 26);
}

.sync-time {
  margin-left: 12px;
  font-size: 21px;
  font-weight: 100;
  color: rgb(26, 26, 26);
}

.mandi-info {
  margin-left: 12px;
  font-size: 21px;
  font-weight: 100;
  color: rgb(26, 26, 26);
}

.download-report-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f7f9fb;
}

.download-report-container .ant-table-thead>tr>th {
  padding: 5px 16px;
}

.download-report-container .column-header-container {
  display: flex;
  flex-direction: column;
  margin: 8px 0px;
}

.download-report-container .ant-table-thead>tr>th .anticon-filter,
.ant-table-thead>tr>th .ant-table-filter-icon {
  height: max-content;
  margin-top: 18px;
  background-color: #558adf;
}

.download-report-container .ant-table-thead>tr>th .ant-table-column-sorter {
  display: flex;
  margin: 11px 0 -5px 0;
}

.download-report-container .ant-table-thead>tr:nth-child(2)>th:nth-child(1) .ant-table-column-sorter,
.download-report-container .ant-table-thead>tr:nth-child(2)>th:nth-child(2) .ant-table-column-sorter,
.download-report-container .ant-table-thead>tr:nth-child(2)>th:nth-child(3) .ant-table-column-sorter,
.download-report-container .ant-table-thead>tr:nth-child(2)>th:nth-child(4) .ant-table-column-sorter {
  display: flex;
  margin: 0 0 0 0;
}

.download-report-container .ant-table-thead>tr>th {
  background: #4772ba;
}

.download-report-container .ant-table-thead>tr:first-child>th {
  padding-top: 0px !important;
  border-radius: 0px;
}

.changed-row {
  background-color: #fffee8 !important;
}

.download-report-container .ant-table-tbody>tr>td {
  background-color: transparent;
}

.top-right-container .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #4872ba;
  border-color: #4872ba !important;
}

.table-container.audit-table .ant-table-fixed-header>.ant-table-content>.ant-table-scroll>.ant-table-body {
  position: relative;
  background: #ffffff !important;
}
.abdswitch {
  background: #eaf0fa;
  box-shadow: inset 0px 6px 9px -11px black, 0px 0px 0px 0px, 0px 0px 0px 0px, 0px 0px 0px 0px;
  line-height: 48px;
}
.abdswitch .tag {
  margin: 0 5px;
  font-weight: 600;
}
.switch-period .tag {
  margin: 0 5px;
  font-weight: 600;
}
.switch-period {
  position: absolute;
  left: 19px;
  top: 70px;
  z-index: 999;
}