.arrival-card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 7px 25px !important;
  width: 280px;
}

.arrival-card-container-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.arrival-card-title {
  color: #1a3062;
  font-size: 12px;
  font-weight: 800;
  margin: 0px 0px 0px 5px !important;
}

.arrival-card-title .button-container {
  display: flex;
  width: 50%;
  justify-content: space-evenly;
}

.arrival-card-container .ant-card-bordered {
  border: 0px solid #e8e8e8;
  border-radius: 5px;
}

.arrival-card-container .ant-card-body {
  padding: 0px 0px 6% 0px;
}

.arrival-card-container .remark-text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px 0px 12px 4px;
}

.remark-text-container .remark-title {
  font-size: 10px;
  font-weight: 400;
  color: #4f73b3;
  margin-left: 4px;
}

.remark-text-container .ant-input {
  border: none;
  border-bottom: solid 1px #fec045;
  border-radius: 0px;
  font-size: 14px;
  font-weight: 400;
  color: #1a3062;
  height: 20px;
  margin-left: 7px;
  width: 92%;
  padding: 0px !important;
}

.ant-btn {
  line-height: 1.499;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-color: blue;
  border: 0px solid transparent;
}

.remark-text-container .ant-input:focus {
  box-shadow: none;
}

.arrival-card-container .bottom-container {
  margin-top: -4px;
}

.arrival-card-container .bottom-container .ant-card-body {

  background-color: #f3f4f8;
  padding: 4px;
  border-radius: 0px 0px 0px 0px;
}

.arrival-card-container .bottom-container .ant-card-bordered {
  border: 0px solid #e8e8e8;
  border-radius: 0px 0px 5px 5px;
  width: 100%;
}

.ant-input-number-input {
  font-weight: 700;
  margin-left: 0px;
}

.min-max-field-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0% 0% 0% 0% !important;
}

.modal-price-min-max h4 {
  margin-top: 6px;
  font-style: italic;
  font-weight: 500;
  color: #1a3062;
}

.input-field-container .right-side-element {
  margin-left: 10%;
  margin-top: 3%;
}

.modal-price-min-max .min-max-field-container .ant-input-number-input-input-wrap {
  width: 70% !important;
  overflow: hidden !important;
}

.min-max-field-container .super-text-left {
  font-size: 9px;
  font-weight: 500;
  color: #4f73b3;
  position: absolute;
  margin: -8px 0px 1px 5px !important;
  z-index: 1;
}

.min-max-field-container .super-text-right {
  font-size: 9px;
  font-weight: 500;
  color: #4f73b3;
  position: absolute;
  margin: -6px 0px 1px 150px;
  z-index: 1;
}

.modal-price-min-max {
  width: 91.5%;
  margin-left: 24px;
  height: 25px;
  display: flex;
  padding: 0 26px !important;
  flex-direction: row;
  justify-content: space-between;
}

.modal-price-min-max .ant-input-number-input {
  margin-left: -8px;
  width: 72px !important;
}

.modal-price-min-max .input-field-container {
  width: 100% !important;
}

/* .modal-price-min-max .ant-input-number {
  width: 80px;
} */

.textarea-container {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  padding: 0 22px 8px 22px;
  color: #1a3062;
  font-size: 12px;
  font-weight: 600;
}