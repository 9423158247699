.temporal-table-expanded .state-name {
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
}

.temporal-table-expanded.disabled{
  pointer-events: none;
}

.temporal-table-expanded .circle {
  border-radius: 10px;
  width: 20px;
  height: 20px;
  text-align: center;
  margin: 0px 0 6px 0;
  color: white;
  font-weight: 800;
  white-space: nowrap;
  cursor: pointer;
}

.temporal-table-expanded .ant-table-row-indent.indent-level-1 {
  padding: 0px !important;
  margin: 0 -24px 0 0;
}

.temporal-table-expanded
  .ant-table-row.ant-table-row-level-1
  .ant-table-row-cell-break-word {
  background-color: white !important;
}

.temporal-table-expanded .variety-row td {
  background-color: #d7e6f8 !important;
}

.temporal-table-expanded .ant-table-thead > tr:nth-child(1) > th:first-child {
  border-right: 4px solid #f7f9fb;
  border-radius: 0 10px 0 0;
}

.temporal-table-expanded .ant-table-thead > tr:nth-child(2) > th:first-child {
  border-left: 4px solid #f7f9fb;
}

.temporal-table-expanded .ant-table-thead > tr:nth-child(1) > th:nth-child(2) {
  border-radius: 10px 0 0 0;
  border-left: 4px solid #f7f9fb;
}

.temporal-table-expanded .ant-table-thead > tr > th {
  height: 10px;
  padding: 8px 16px;
}

.temporal-button {
  cursor: pointer;
  width: 18px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0px 0 5px 35%;
  height: 32px;
}

.modified-input {
  border: 1px solid #122144;
}

.no-pointer-events {
  pointer-events: none;
  cursor: not-allowed;
}

.temporal-table-expanded .ant-input-number-input {
  padding: 0px 4px;
  text-align: right;
  /* height: 15px; */
}

.temporal-table-expanded .ant-input-number-input-wrap {
  width: 100%;
}

.temporal-table-expanded .ant-table-tbody > tr > td {
  padding: 5px 2px 1px 2px;
}

.temporal-table-expanded .ant-input-number {
  /* font-size: 12px; */
  width: 68px;
  float: right;
  margin: 0 2px 0 0;
  /* height: 21px; */
}

.temporal-table-expanded .ant-input {
  margin: 0 0 5px 0;
}

.qc-monthly-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.qc-monthly-modal .qc-consolidated-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-radius: 10px;
  margin: 10px 0 0 0;
}

.qc-monthly-modal .qc-info-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 -52px 0 0;
}

.qc-monthly-modal .info-box {
  border-radius: 10px;
  white-space: nowrap;
  padding: 10px;
}
.qc-monthly-modal .info-box-row {
  border-radius: 10px;
  white-space: nowrap;
  padding: 10px;
}

.qc-monthly-modal .circle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 25px;
  width: 50px;
  height: 50px;
  text-align: center;
  color: white;
  font-weight: 800;
  white-space: nowrap;
  margin: 0 30%;
}

.qc-monthly-modal .responsive-graph-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.qc-monthly-modal .graphs-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1200px;
  /* overflow-x: scroll; */
}

/* media queries */
/* @media screen and (max-width: var(--lg)) {
  .qc-monthly-modal .responsive-graph-container {
    flex-direction: row;
  }

  .qc-monthly-modal .qc-info-container {
    width: 800px;
  }
}

@media screen and (max-width: var(--md)) {
  .qc-monthly-modal .responsive-graph-container {
    flex-direction: column;
  }
  .qc-monthly-modal .qc-info-container {
    width: 800px;
  }
} */
