.arrival-page-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.map-right-container {
  background-color: white;
  border-radius: 17px;
  width: 256px;
  height: 226px;
  position: absolute;
  display: flex;
  flex-direction: column;
  margin: 11px 0 0px -11px;
  box-shadow: -1px 3px 17px -6px;
}

.map-left-container {
  background-color: white;
  border-radius: 17px;
  width: 156px;
  height:126px;
  align-self: flex-start;
  display: flex;
}

.input-container .ant-slider-handle {
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  border: solid 2px #fab730;
  box-shadow: 0px 0px 0px 0px;
}

.input-container .ant-slider:hover .ant-slider-handle .ant-slider-handle-1 .ant-slider-handle-2 {
  border: solid 2px #fab730;
}

.input-container .ant-slider-handle:focus {
  border-color: #fab730 !important;
}

.input-container .ant-slider-handle:hover {
  border-color: #fab730;
  box-shadow: 0 0 0 5px #0a123c0f;
}

.input-container .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #fab730;
}

.arrival-page-container .arrival-category-list-container {
  height: 468px;
  display: flex;
  flex-direction: row;
  background-color: #e8edf2;
}

.arrival-page-container .map-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  height: 40vh;
}


.arrival-page-container .map-container .summary-container .summary {
  cursor: pointer;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background: #85d8ff99;
  width: 130px;
  height: max-content;

  align-items: center;
  border-radius: 3px;
  margin: 5px;
}
.arrival-page-container .map-container .summary-container1 .summary1 {
  cursor: pointer;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 5;
  background: #85d8ff99;
  width: 130px;
  height: 30px;
margin-top: 100px;
  align-items: center;
  border-radius: 3px;
  margin: 5px;
}

.summary .completed-container {
  display: flex;
  flex-direction: row;
  margin-left: 11px;
  width: 100px;
  justify-content: space-between;
  margin-top: 5px;
  font-size: 11px;
  font-weight: 900;
  color: #1a3062;
  width: 119px;
}
.table-container varietal-audit-table .hub-form-container .ant-modal-content {
  max-height: 450px;
}

.varaital-modal11 .varietal-audit-table1 .hub-form-container1 .arrival-table .hide {
  visibility: false;
  
}

varaital-modal11 .table-container varietal-audit-table .hub-form-container .ant-modal-body {

  padding-top: 5px;
}
varaital-modal11 .table-container varietal-audit-table .hub-form-container .ant-table-tbody>tr>td {
  padding: 8px 8px;
  max-height: 100px i !important;
  overflow-wrap: break-word;
}
.varaital-modal11 .table-container varietal-audit-table1 .hub-form-container1 .ant-table-fixed-header>.ant-table-content>.ant-table-scroll>.ant-table-body {

  background-color: rebeccapurple;
}

.arrival-table .ant-table-fixed-header>.ant-table-content>.ant-table-scroll>.ant-table-body {
  position: relative;
  height: 250px;
  background: #ffffff !important;
}

/* .summary .completed-container .ant-progress-circle-path {
  stroke: #1a3062 !important;
} */
.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #1a3062 !important;
  stroke-width: 20px;
}

.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-trail {
  stroke: #f1f1f1 !important;
  stroke-width: 20px;
}

.summary .completed-container .progress-bar {
  margin-top: -4px;
  margin-right: 4px;
  width: 20px;
  height: 20px;
}

.summary .completed-container .progress-bar .ant-progress-inner {
  width: 15px;
  height: 150px;
}

.arrival-page-container .map-container .summary-container .summary .summary-text {
  color: #27406b;
  font-weight: 600;
  padding: 0% 14.5%;
  margin-top: -2px;
  white-space: nowrap;
  cursor: pointer;
  height: 28px;
  margin-top: 6px;
  margin-left: -10px;
}

.arrival-page-container .map-container .leaflet-container {
  height: 250px;
  width: 100%;
  z-index: 0;
}

.arrival-page-container .map-container .leaflet-container .leaflet-control-container {
  display: none;
}

.arrival-page-container .map-container .input-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0px 0 0 88px;
  position: absolute;
}

.arrival-page-container .map-container .input-container .switch-container {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  justify-content: space-around;
  align-items: center;
  height: 33px;
  width: 156px;
  border-radius: 5px;
  background-color: white;
}

.switch-container .ant-switch-inner {
  font-size: 0px;
}

.switch-container .ant-switch {
  background-color: #fec045;
}

.switch-container .ant-switch::after {
  background-color: #1a3062;
}

/* styles for new custom slider */

.input-container .new-custom-slider {
  margin-bottom: -15px;
}

.input-container .new-custom-slider .caption {
  margin-top: 13px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #1a3062;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.input-container .new-custom-slider .caption p {
  cursor: pointer;
}

.input-container .new-custom-slider .caption .selected {
  font-size: 13px;
  margin-top: -1px;
  font-weight: 800;
}

.input-container .new-custom-slider .ant-slider-rail,
.input-container .new-custom-slider .ant-slider-rail:hover {
  margin-top: -2px;
  width: 117%;
  height: 20px;
  background-color: #1a3062 !important;
  border-radius: 19px;
}

.input-container .new-custom-slider .ant-slider-handle {
  background-color: #ffffff;
  border: solid 2px #ffb117;
  width: 16px;
  height: 16px;
  margin-top: 0px;
  margin-left: 10px;
}

.input-container .new-custom-slider .ant-slider-handle:hover {
  box-shadow: none;
}

.input-container .new-custom-slider .ant-slider-track {
  display: none;
  height: 18px;
  background-color: #f4c15c;
  margin-top: 0px;
  border-radius: 19px;
  margin: 0 2px;
}

.input-container .new-custom-slider .ant-slider {
  width: 78%;
}

.input-container .new-custom-slider .ant-slider:hover {
  width: 78%;
}

.dropdown-container {
  display: flex;
  flex-direction: row;
}

#rcDialogTitle0 {
  font-size: 20px;
}
.dropdown-container .dropdown-label {
  width: 100px;
  font-size: 11px;
  font-weight: 800;
  margin: 16px -30px 0px -75px;
}

.map-right-container .input-container .ant-select-selection {
  background-color: #f8f8f8;
  margin-top: 5px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 400;
  width: 156px;
  height: 29px;
  padding-left: 5px;
  align-items: flex-end;
  color: #646665;
}

.map-right-container .input-container .ant-select-selection:hover {
  background-color: #fbfbfb;
}

.priority-checkbox {
  font-size: 11px;
  color: black;
  font-weight: 500;
  margin: 5px 0px 0px -50px;
}

.region-container .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1a3062;
  border-color: #1a3062;
}

.navigation-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 55px;
  margin: 2px 0px 0px 94px;
}

.navigation-container .nav-button {
  display: flex;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  background-color: #1a3062;
  cursor: pointer;
}

.navigation-container .disabled-button {
  background-color: #b8b8b8;
  pointer-events: none;
}

.arrival-page-container .hidden-checkbox {
  pointer-events: none;
  opacity: 0;
}