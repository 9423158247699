:root {
  --lg: 1000px;
  --md: 700px;
  --sm: 480px;
}

body {
  background-color: #f7f9fb !important;
}

.code-box-demo {
  text-align: center;
}

.ant-menu-dark {
  background-color: #1a3062;
}

.ant-layout-header,
.ant-layout-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  color: #fff;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  padding: 3px !important;
}

* {
  box-sizing: border-box;
}

/* ...................ScrollBar..................... */

/* width */
::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px grey;
  border-radius: 10px;
  width: 8px;
  height: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cccccc;
  opacity: 0.7;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c2c0c0;
  opacity: 0.5;
}

/* Loading spinner */

.ant-spin {
  color: #1a3062;
}

.ant-spin-dot-item {
  background-color: #1a3062;
}


.ant-table-column-sorters::before {
  background: #4672ba !important;
}

.ant-input-number-handler-wrap {
  display: none !important;
}

.ant-select-dropdown {
  min-width: 140px !important;
}

.ant-select-dropdown-menu-item {
  white-space: pre-line !important;
}

.ant-table-fixed-header>.ant-table-content>.ant-table-scroll>.ant-table-body {
  position: relative;
  background: #eff3f6 !important;
}
.gm-style-iw button.gm-ui-hover-effect {
  display: none !important;
}
.ant-table-header {
  overflow: hidden;
  background: #f0f2f5 !important;
  margin-right: inherit !important;
}

.ant-table-bordered .ant-table-tbody>tr>td {
  background-color: white;
}

td,
th {
  cursor: context-menu;
}

/* .ant-select-single:not(.ant-select-customize-input) */
.ant-select-selection-search-input {
  color: rgb(26, 26, 26);
}

.ant-select-search--inline .ant-select-search__field {
  width: max-content !important;
}

/* sort and filter styles */
.ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner {
  color: #cdd6e5 !important;
}

.ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on,
.ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on {
  color: #7dafff !important;
}

:root .ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up,
:root .ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down {
  font-size: 20px !important;
  height: 8px !important;
}

.ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner-full {
  margin-top: -0.3em !important;
}

/* .download-report-container .ant-table-thead > tr > th .anticon-filter,
.ant-table-thead > tr > th .ant-table-filter-icon {
  height: max-content !important;
  margin-top: 18px !important;
  width: 25px !important;
  color: #cdd6e5 !important;
  background-color: #558adf !important;
} */
.download-report-container .ant-table-thead>tr>th .anticon-filter,
.ant-table-thead>tr>th .ant-table-filter-icon {
  height: 18px !important;
  margin-top: 9px !important;
  margin-right: 10px !important;
  border-radius: 9px !important;
  width: 26px !important;
  color: #cdd6e5 !important;
  background-color: #558adf00 !important;
}


.anticon.anticon-filter.ant-table-filter-selected.ant-dropdown-trigger {
  color: white !important;
  background-color: #558adf !important;
}

/* .anticon.anticon-filter.ant-dropdown-trigger {
  height: 18px !important;
  margin-top: 10px !important;
  margin-right: 10px !important;
  border-radius: 9px !important;
  width: 26px !important;
  color: #cdd6e5 !important;
  background-color: #558adf !important;
} */

/* Box-shadows */

.box-shadow-card {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px !important;
}

.box-shadow-hollow {
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset !important;
}

.box-shadow-hover-heavy {
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px !important;
}

.box-shadow-bordered {
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}

.box-shadow-card-pressed {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

/* Animations */

.grow {
  transition: all 0.2s ease-in-out;
}

.grow:hover {
  transform: scale(1.1);
}

/* Generic */

.title-generic {
  font-size: 15px;
  font-weight: 700;
  color: #4672ba;
  margin: 10px 10px;
}