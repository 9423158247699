.alert-form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.alert-form-container .form-top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.alert-form-container .form-bottom-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin: 8px 0 0 0;
}

.alert-form-container .form-top-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 47%;
  margin: 0 15px 0 0;
  padding: 0 17px 0 0;
}

.alert-form-container .form-top-right {
  display: flex;
  flex-direction: column;
  width: 53%;
}

.alert-form-container .form-radio-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  border-radius: 5px;
  height: max-content;
  margin: 7px 0px 0 0px;
  box-shadow: -1px -1px 4px 0px;
}

.alert-form-container .ant-select,
.ant-cascader-picker {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #80808047;
}

.alert-form-container .ant-form-item-label {
  line-height: 24px;
}

.alert-form-container .ant-select-selection {
  font-size: 14px;
  font-weight: 400;
}

.alert-form-container .ant-input-number {
  width: 100% !important;
}

.alert-form-container .ant-radio-wrapper {
  margin: 10px 10px 10px 10px;
  border: 1px solid #5b7da2;
  border-radius: 5px;
  padding: 8px 1px 8px 5px;
}

.alert-form-container .ant-radio-checked .ant-radio-inner {
  border-color: #154479;
}

.alert-form-container .ant-radio-inner::after {
  background-color: #154479;
}

.alert-form-container .ant-radio-wrapper-checked {
  background-color: #f2f7fc;
}

/* .alert-form-container .ant-radio {
  margin: 0 0 0px 170px;
} */

/* .alert-form-container span.ant-radio + * {
  padding-right: 8px;
  padding-left: 8px;
  margin-left: -192px;
} */

.alert-form-container .ant-btn {
  background-color: #154479;
}

.alert-form-container .ant-btn:hover {
  background-color: #154479;
}

.alert-form-container .alert-text-area {
  width: 96%;
}

.alert-form-container .ant-checkbox-group {
  margin: 10px 0px 10px 16px;
}

.alert-form-container .ant-checkbox-group-item {
  margin: 0 9px 0 0;
  padding: 8px 5px 8px 13px;
  border-radius: 5px;
  border: 1px solid #5b7da2;
}

.alert-form-container .form-top-right .has-error .ant-form-explain {
  color: #f5222d;
  text-align: left;
}

.alert-form-container .ant-checkbox-wrapper-checked {
  background-color: #f2f7fc;
}

.alert-form-container .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #154479;
  border-color: #154479;
}

.alert-form-container .ant-input-textarea-clear-icon {
  margin: 8px 18px 0 0;
}

.alert-form-container .ant-calendar-picker {
  width: 315px !important;
}

.alert-form-container .submit-button {
  margin: -4px 30px 0px 0px;
  width: 115px;
  height: 46px;
  font-size: 15px;
}