.projection-container .competitor-form-container.ant-form {
  width: inherit;
}

.projection-container .competitor-form-container .ant-btn {
  background-color: #154479;
  margin: 10px 0 0 0;
}

.projection-container .holder {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.projection-container .holder .temporal-button {
  align-self: flex-end;
  padding: 0px 30px;
  border: solid 2px #154479;
  border-radius: 5px;
}

.projection-container .holder .title {
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  color: #154479;
  text-decoration: underline;
}
.weekly-volume-container .top-container .filter-list {
  margin: 8px 0px 0 0px !important;
}
.weekly-volume-container .top-container .filter-list .ant-calendar-picker {
  padding: 4px;
  width: 150px;
}

.competitor-form-container .ant-input-disabled {
  color: #353535d1;
  background-color: #eaeaea;
  cursor: not-allowed;
  opacity: 1;
  font-weight: 500;
}

.competitor-form-container .disabled-button {
  background-color: #989393 !important;
  color: #fbfbfb !important;
  pointer-events: none;
  cursor: not-allowed !important;
}
