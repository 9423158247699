.consolidated-table {
  padding: 41px 16px;
  margin: 6px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 600px;
  height: 355px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 2px 2px 12px -7px black;
}

.consolidated-container {
  display: flex;
  flex-direction: column;

  overflow: scroll;
}

/* for filters */
.consolidated-container .summary .ant-select-selection {
  background-color: white;
  color: #4672ba;
  padding-left: 8px;
  font-size: 14px;
  width: 150px;
  border: 0px solid #d9d9d9;
}

.consolidated-container .summary .ant-select-selection {
  color: #313131 !important;
}

.consolidated-container .ant-calendar-range-picker-input {
  width: 44%;
  color: #313131;
  font-weight: 500;
}

.filter-container {
  margin-bottom: 25px;
  background: transparent;
}

.consolidated-container .summary .summary-filters {
  background-color: #f7f9fb;
  padding: 13px 0px 0px 0px;
  justify-content: space-around;
}

.consolidated-container .summary .filter-container {
  margin-bottom: 12px;
  background: transparent;
}

.consolidated-container .summary .ant-select-selection:hover,
.consolidated-container .summary .ant-select-selection:focus {
  border: none !important;
}

.consolidated-container .ant-calendar-picker-input {
  outline: none;
  border: none;
  margin-left: -6px;
}

.ant-select-arrow .ant-select-arrow-icon svg {
  color: #acacac;
}

.filter-select {
  border-radius: 8px;
  padding: 5px 2px 0px 2px;
  background-color: white;
  border: 1px solid rgb(221, 221, 221);
  box-shadow: 2px 2px 6px -6px black;
}

.filter-select-title {
  color: rgb(172, 172, 172);
  padding-left: 7%;
  margin-bottom: -5px;
  font-size: 11px;
}

/* table header */
.col-container {
  font-size: 20px;
  font-weight: 200;
  line-height: 15px;
}

.bottom-container {
  display: flex;
  flex-direction: row;
}

.bottom-container h1 {
  margin-bottom: 0px;
  color: #eeeff2;
  font-weight: 500;
  font-size: 14px;
}

/* table design */
.variety-consolidated-table .ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 4px 4px 0 0;
  border-collapse: separate;
  padding: 0px 10px 0px 20px;
}

.variety-consolidated-table .ant-table-thead>tr>th,
.ant-table-tbody>tr>td {
  padding: 7px 16px;
  font-weight: 600;
  color: #262626;
}

.variety-consolidated-table .ant-table-thead>tr:first-child>th {
  background-color: #030a30;
  padding: 5px 16px;
}

.variety-consolidated-table .ant-table-thead>tr:nth-child(2)>th {
  background-color: #0a123c;
}

.variety-consolidated-table .ant-table-thead>tr>th {
  background: #4571ba;
  text-align: left;
  vertical-align: bottom;
}

.variety-consolidated-table .ant-table-thead>tr>th .ant-table-header-column {
  font-size: 14px;
  font-weight: 400;
}

.variety-consolidated-table .ant-table-thead>tr:first-child>th:first-child,
.variety-consolidated-table .ant-table-thead>tr:nth-child(3)>th:first-child,
.variety-consolidated-table .ant-table-thead>tr:nth-child(2)>th:first-child {
  background-color: #4571ba;
  border-spacing: 3px;
  border-right: 3px solid #f7f9fb;
}

.variety-consolidated-table .ant-table-thead>tr:nth-child(3)>th {
  background-color: #0a123c;
  border-color: #1f2649;
}

.variety-consolidated-table .ant-table-thead>tr:nth-child(2)>th {
  background-color: #030a30;
  border-bottom: none !important;
}

.variety-consolidated-table .ant-table-thead>tr:nth-child(3)>th:first-child {
  background-color: #3c5a93;
}

.variety-consolidated-table .ant-table-thead>tr:first-child>th:first-child {
  border-bottom: 1px solid #ffffff24;
}

.variety-consolidated-table .ant-table-thead>tr:nth-child(2)>th:nth-child(2),
.variety-consolidated-table .ant-table-thead>tr:nth-child(2)>th:nth-child(3),
.variety-consolidated-table .ant-table-thead>tr:nth-child(2)>th:nth-child(4),
.variety-consolidated-table .ant-table-thead>tr:nth-child(2)>th:nth-child(5),
.variety-consolidated-table .ant-table-thead>tr:nth-child(2)>th:nth-child(6),
.variety-consolidated-table .ant-table-thead>tr:nth-child(2)>th:nth-child(7),
.variety-consolidated-table .ant-table-thead>tr:nth-child(2)>th:nth-child(8),
.variety-consolidated-table .ant-table-thead>tr:first-child>th:nth-child(2),
.variety-consolidated-table .ant-table-thead>tr:first-child>th:nth-child(3) {
  border-bottom: 1px solid #1f2649;
  border-right: 1px solid #1f2649;
}

.variety-consolidated-table .ant-table-thead>tr:nth-child(3)>th {
  border-bottom: none !important;
}

.variety-consolidated-table .ant-table-thead>tr:nth-child(2)>th {
  border-bottom: 0px solid;
}

.variety-consolidated-table .ant-table-thead>tr:nth-child(2)>th:last-child {
  border-right: 0px solid;
}

.variety-consolidated-table .ant-table-thead>tr:nth-child(2)>th:nth-child(1) {
  font-size: 11px !important;
  color: #4571ba;
}

.variety-consolidated-table .ant-table-thead>tr:nth-child(2)>th:nth-child(1) .ant-table-header-column {
  font-size: 12px;
  color: #d5d5d5;
}

.variety-consolidated-table .ant-table-tbody>tr>td:nth-child(1) {
  border-right: 3px solid #f7f9fb;
}

.variety-consolidated-table .ant-table-tbody>tr>td {
  border: 1px solid #f7f9fb;
}

.variety-consolidated-table .ant-table-tbody>tr>td {
  background-color: white;
}