.top-container .volume-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 30px 0 0 13px;
}

.top-container.wider {
  width: 90% !important;
}

.top-container .user-info {
  margin: 10px 0 10px 0;
  border-radius: 7px;
  width: -moz-fit-content;
  width: fit-content;
  background: white;
  text-align: center;
  max-width: 220px;
}

.volume-button-container .volume-button {
  /* margin: 0 2px 0 2px; */
  color: white;
  background-color: #5ebaf4;
  pointer-events: none;
}
.volume-button-container .volume-button-disabled {
  /* margin: 0 2px 0 2px; */
  color: #d6d6d6;
  background-color: white;
}
