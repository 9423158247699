.alert-management-container {
  display: flex;
  flex-direction: column;
}

.alert-management-container .top-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 98.5%;
  margin: 7px 3px 7px 9px;
}

.ant-table-thead > tr > th .ant-table-header-column {
  text-align: left;
}

.alert-action-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.alert-management-container .edit-button {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 80px;
  height: 24px;
  border-radius: 11px;
  background-color: #f0f0f0;
  cursor: pointer;
}

.user-management-container .ant-table-bordered .ant-table-tbody > tr > td {
  color: #262626;
  font-weight: 600;
  vertical-align: middle;
}

.user-management-container .ant-select-selection {
  background-color: white;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 400;
  align-items: flex-end;
  color: #646665;
  border: 1px solid rgba(128, 128, 128, 0.281);
  height: 100%;
  margin: 0 0px 0 5px;
}
.user-management-container .ant-select-selection:hover {
  background-color: white;
}

.user-management-container .ant-select {
  padding-top: 0px;
}

.add-alert-button {
  display: flex;
  justify-content: flex-end;
  color: white;
  background-color: #154479;
  padding: 5px 10px;
}

.add-alert-button.ant-btn:hover,
.add-alert-button.ant-btn:active,
.add-alert-button.ant-btn:focus {
  background-color: #154479 !important;
  color: white !important;
}

.ant-modal-footer .ant-btn,
.ant-modal-footer .ant-btn:hover,
.ant-modal-footer .ant-btn:active,
.ant-modal-footer .ant-btn:focus {
  background-color: #154479 !important;
  color: white !important;
}

.alert-management-modal .ant-modal-close-x .anticon {
  color: white;
}

.alert-management-modal .ant-modal-header {
  background: #154479;
}

.alert-management-modal .ant-modal-title {
  color: white;
}

.alert-management-modal .ant-modal-footer {
  display: none;
}

.alert-management-modal .ant-modal-body {
  background-color: white;
  border-radius: 0 0 10px 10px;
  padding: 2px 24px 10px 24px;
}
