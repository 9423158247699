.action-item-container {
  display: flex;
  flex-direction: row;
  width: 30px;
}

.action-item-container .action-spinner {
  width: "inherit";
  height: "inherit";
}

.action-button-disabled {
  pointer-events: none !important;
  cursor: not-allowed !important;
}

.action-item-container .action-button img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.modal-table-container .ant-table-bordered .ant-table-tbody>tr>td:nth-child(12) .combined-columns-container {
  margin: 0px -5px !important;
}

.context-menu {
  margin-top: -16px;
}

.comment-reject {
  cursor: pointer;
}