.qc-monthly-varieties-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.qc-monthly-varieties-container .filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: white;
  margin: 10px;
}

.qc-monthly-varieties-container
  .ant-select-selection--multiple
  .ant-select-selection__rendered {
  height: auto;
  margin-bottom: -3px;
  margin-left: 5px;
  font-size: small;
}

.qc-monthly-varieties-container .ant-select {
  max-width: 430px;
  padding: 4px 0 9px 0;
}
