.dashboard-map-marker-tooltip {
  color: #4670ba;
  font-weight: lighter;
  border-radius: 5px;
  padding: 0 5px 0 10px;
}

.dashboard-map-marker {
  height: 10px;
  width: 10px;
  border-radius: 5px;
  cursor: default !important;
}

.dashboard-map-marker-factory {
  height: 10px;
  width: 10px;
  cursor: default !important;
}